import config from '../config.json'
// import { toast } from 'react-toastify'

export const getServerIp = () => {
    if(!config.isProduction){
        return config.devIP
    }
    
    return config.productionIP
}


export const httpHeader = (method, data=null, toJson=false) => {
    const header = {
        method,
        headers: { }
    }

    if(data !== null){
        header.body = toJson ? JSON.stringify(data) : data 
        if(toJson){
            header.body = JSON.stringify(data)
            header.headers["Content-Type"] = "application/json"
        }
        else{
            header.body = data
        }
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}