import { request } from "../RequestManager"
import { getServerIp, httpHeader } from "../apiUtils"




const login = async (username, password) => {
    const url = `${getServerIp()}rest-auth/login/`
    const header ={
        method:"POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({username, password})
    }
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 200){
            localStorage.setItem('token', body.key.toString())
            localStorage.setItem('username', `"${body.user.username.toString()}"`)
            
            return body
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const removeToken = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("username")
}


const UserService = {
    login,
    removeToken,
}

export default UserService