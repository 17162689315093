


const HowToUsePage = () => {


    return (
        <div className="max-w-6xl m-auto">
            <div class="p-8 bg-gray-100 rounded-lg shadow-md">
                <h1 class="text-2xl font-bold text-center text-gray-800 mb-4">دليل استخدام المنصة 📘</h1>

                <div class="mb-6">
                    <h2 class="text-xl font-semibold text-gray-700">١. بدء الاستخدام</h2>
                    <p class="text-gray-600 mt-2">تم تصميم المنصة لتكون بسيطة وسهلة الاستخدام، تشبه قراءة كتاب تعليمي. يتوفر لك صندوق الكود في الجزء الأيسر من الشاشة، حيث يمكنك كتابة التعليمات البرمجية وتحريرها مباشرة.</p>
                </div>

                <div class="mb-6">
                    <h2 class="text-xl font-semibold text-gray-700">٢. زر "تشغيل"</h2>
                    <p class="text-gray-600 mt-2">عند الانتهاء من كتابة التعليمات البرمجية، اضغط على زر <span class="text-green-600 font-bold">تشغيل</span> لتنفيذ الكود ومشاهدة النتائج. يظهر الناتج مباشرة في شاشة الإخراج حتى تتمكن من رؤية التغييرات فورًا.</p>
                </div>

                <div class="mb-6">
                    <h2 class="text-xl font-semibold text-gray-700">٣. زر "مسح"</h2>
                    <p class="text-gray-600 mt-2">إذا كنت ترغب في إعادة المحاولة أو البدء من جديد، يمكنك استخدام زر <span class="text-red-600 font-bold">مسح</span> لإزالة الكود الحالية وتنظيف صندوق الكود.</p>
                </div>

                <div class="mb-6">
                    <h2 class="text-xl font-semibold text-gray-700">٤. زر "مساعدة"</h2>
                    <p class="text-gray-600 mt-2">في حال احتجت إلى دعم أو توجيه، يمكنك النقر على زر <span class="text-blue-600 font-bold">مساعدة</span> للاطلاع على النصائح والإرشادات التي تساعدك على إتمام المهام.</p>
                </div>

                <div class="mb-6">
                    <h2 class="text-xl font-semibold text-gray-700">٥. الإنجازات</h2>
                    <p class="text-gray-600 mt-2">أثناء تقدمك في الدورة، ستحصل على إنجازات عند إكمال التحديات. يُظهر كل إنجاز تقدمك ويشجعك على تحسين مهاراتك في البرمجة. تابع جمع الإنجازات كجزء من رحلتك التعليمية.</p>
                </div>

                <div>
                    <h2 class="text-xl font-semibold text-gray-700">٦. التنقل بين الدروس</h2>
                    <p class="text-gray-600 mt-2">الدروس مرتبة في الجهة اليمنى من الشاشة، مثل كتاب تعليمي. يمكنك التنقل بين الدروس للانتقال من موضوع إلى آخر والعودة إلى الدروس السابقة.</p>
                </div>
            </div>

        </div>
    )
}

export default HowToUsePage