




const OutputMessageComponent = ({status, msg, timestamp, formatedTime}) => {
    // 
    switch (status) {
        case "successful":
            return <div>{`[${formatedTime.trim().toUpperCase()}] => ${msg}`}</div>
        case "error":
            return <div className="text-red-500">{`🛑 ${msg}`}</div>
        case "warning": 
            return <div className="text-red-500">{`${msg}`}</div>
        case "critical": 
            return <div className="text-red-500 animate-pulse">{`⛔️ ${msg}`}</div>
        case "level_complete":
            return <div className="text-green-500 font-medium text-center">✨ {msg} ✨</div>
        case "level_validator_debug":
            return <div className={`${msg === "true" ? "text-green-500" : "text-red-500"}`}>{`(${formatedTime?.trim() || ""}) validator => ${msg}`}</div>
        default:
            return <div>{status} {"=>"} {msg}</div>
    }
}

export default OutputMessageComponent