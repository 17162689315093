


const AchievementsPage = () => {

    return(
        <div className="max-w-6xl m-auto pb-14 pt-4">
            <div className="pb-2">
                <h1 dir="rtl">الإنجازات (3/1)</h1>
            </div>
            <div>
                <h2 className="pb-4">مقدمة في البايثون</h2>
                <div className="m-auto grid grid-cols-4 gap-y-11 self-center place-items-center select-none">
                    <div className="border rounded-lg w-36 flex flex-col items-center justify-center h-60 relative">
                        <img src="https://sensorsa-blog.s3.eu-central-003.backblazeb2.com/noun-learning-5514061.png" alt="achievement 1" className="object-contain p-2 border border-black rounded-full aspect-square text-center w-16 flex items-center justify-center text-3xl"/>
                        <h2 className="pt-6 font-bold">بداية ممتازة</h2>
                        <h3 className="absolute bottom-0 pb-3 text-slate-500 text-sm">27/10/2024</h3>
                    </div>
                    <div className="border rounded-lg w-36 flex flex-col items-center justify-center h-60">
                        <h1 className="border border-black rounded-full aspect-square text-center w-16 flex items-center justify-center text-3xl animate-blink duration-600 ">?</h1>
                        <h2 className="pt-6 font-bold">خبير المتغيرات</h2>
                    </div>
                    <div className="border rounded-lg w-36 flex flex-col items-center justify-center h-60">
                        <h1 className="border border-black rounded-full aspect-square text-center w-16 flex items-center justify-center text-3xl animate-blink duration-600">?</h1>
                        <h2 className="pt-6 font-bold">كود مختصر</h2>
                    </div>
                    <div className="border rounded-lg w-36 flex flex-col items-center justify-center h-60">
                        <h1 className="border border-black rounded-full aspect-square text-center w-16 flex items-center justify-center text-3xl animate-blink duration-600">?</h1>
                        <h2 className="pt-6 font-bold">محترف التكرارات</h2>
                    </div>
                    <div className="border rounded-lg w-36 flex flex-col items-center justify-center h-60">
                        <h1 className="border border-black rounded-full aspect-square text-center w-16 flex items-center justify-center text-3xl animate-blink duration-600">?</h1>
                        <h2 className="pt-6 font-bold">منظّم البيانات</h2>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AchievementsPage