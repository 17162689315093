/* eslint-disable no-eval */
/* eslint-disable no-new-func */
import { createContext, useContext, useEffect, useState } from "react"
import pythonParser from "../parser";
import FirebaseService from "../services/FirebsaeService";
import { usePy } from "./PyContext";
import useLocalStorageState from 'use-local-storage-state'


const codeControllerContext = createContext();


export function CodeControllerContextProvider({ children }) {
    const [inputValue, setInputValue] = useState(`print("hello")`)
    const [levels, setLevels] = useState([])
    const [activeLevel, setActiveLevel] = useState(0) //index
    const [hasLevelGoal, setHasLevelGoal] = useState(false)
    const [solved, setSolved] = useLocalStorageState('solved', {
        defaultValue: []
    })

    const { run, displayLevelCompleteMsg, clearOutput } = usePy()

    useEffect(() => {
        let mounted = false

        if(!mounted){
            FirebaseService.all()
            .then((data) => setLevels(data))
        }
    }, [])

    const lux = (s) => {
        return pythonParser.parse(s)
    }

    const validateQuestion = (s, index, luxOutput) => {
        const level = levels[index]
        if(level !== -1  && level?.validator !== undefined){
            try {
                
                const validatorFunction = new Function(level?.validator)
                const validatorResults  = validatorFunction.call({lux:luxOutput || []}) || false

                return validatorResults
            } catch (error) {
                console.error(error);
            }
        }
        else{
            console.error('error #V2');
        }
        return false
    }

    const onLevelDone = (levelIndex) => {
        displayLevelCompleteMsg(`تم الانتهاء من ${levels[activeLevel]?.level_name}`)
        setSolved((prev) => [...prev, levels[activeLevel]?.id])
        setNextLevel()
    }

    const onRun = async (s) => {
        try {
            await run(s)
            const luxOutput = lux(s)

            if(luxOutput){
                const validationResults = validateQuestion(s, activeLevel, luxOutput)
                console.log('validation res ', validationResults);
                if(validationResults && !solved.includes(levels[activeLevel]?.id)){
                    onLevelDone(activeLevel)
                }
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    const onActiveLevelChange = (index) => {
        setActiveLevel(index)
    }

    const setNextLevel = () => {
        if(levels.length > 0 ){
            let activeIndex = activeLevel
            const nextIndex = ++ activeIndex % levels.length 
            setActiveLevel(nextIndex)
        }
    }

    const setPreviousLevel = () => {
        if(levels.length > 0){
            const len = levels.length;
            setActiveLevel((activeLevel + len - 1) % len) 
        }
    }

    const resetProgress = () => {
        setSolved([])
        clearOutput([])
    }

    // const setLevelDone = (level) => {
    //     const temp = levels.map((lvl) => {
    //         if(lvl.id === level.id){
    //             lvl.isDone = true
    //         }
    //         return lvl
    //     })

    //     setLevels(temp)
    // }

    const onRunOLD = () => {
        // const parseTree  = lux(inputValue)
        // const validation = levels[activeLevel].validator(parseTree.body)

        // if(validation){
        //     setLevelDone(levels[activeLevel])
        // }
    }

    return (
        <codeControllerContext.Provider
          value={{
            levels,
            activeLevel,
            hasLevelGoal,
            inputValue,
            onRun,
            solved,
            onActiveLevelChange,
            setInputValue,
            setPreviousLevel,
            setNextLevel,
            resetProgress
          }}
        >
          {children}
        </codeControllerContext.Provider>
      )
}

export function useCodeController() {
    return useContext(codeControllerContext);
}