import { useEffect } from "react";
import CourseService from "../api/services/CourseService";
import { useCourseService } from "../hooks/useCourseService";
import CourseMainCard from "../components/CourseMainCard";
import LoadingComponent from "../components/LoadingComponent";
import { Link } from "react-router-dom";





function HomePage() {
    // const {inputValue, setInputValue, onRun} = useCodeController()
    // const {output, clearOutput } = usePy()
    const { courses, isLoading, errorMsg } = useCourseService()
    
    return (
        <div className="h-screen-" >
            <LoadingComponent isLoading={isLoading} errorMsg={errorMsg} />
            <div className="grid place-items-center grid-cols-1 gap-x-2 gap-y-4 md:grid-cols-2 xl:grid-cols-3 max-w-6xl m-auto pb-14 pt-4">
                {
                    courses?.map((course) => (
                        <div key={course.id}>
                            <CourseMainCard course={course} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default HomePage;
