import { useNavigate } from "react-router-dom"


const CourseMainCard = ({course}) => {
    const navigator = useNavigate()

    const onCardButtonClick = () => {
        if(course.isRegistered){
            navigator(`course/${course.id}`)
        }
        else{
            navigator("/not-implemented", {state:{source:`${course.name}-card-button`}})
        }
    }

    return(
        <div className="border w-[350px] rounded-lg shadow-md">
            <div className="w-full bg-green-600 h-[80px] rounded-t-lg"/>
            <div className="px-2 pt-1 pb-3">
                <h1 className="pt-1 pb-2 font-bold">{course.name}</h1>
                <textarea className="h-[150px] resize-none w-full overflow-auto" dir="auto" defaultValue={course.description} />
                <button onClick={() => onCardButtonClick()} className={`rounded-lg text-center w-full ${course.isRegistered ? "bg-blue-600" : "bg-green-600"} py-2 text-white`}>{course.isRegistered ? "الدخول الى الدورة" : "سجل في الدورة"}</button>
            </div>
        </div>
    )
}

export default CourseMainCard