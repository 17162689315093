import { useEffect } from "react"
import { usePy } from "../context/PyContext"
import { useState } from "react"
import LevelsAdminService from "../api/services/LevelsAdminService"



export const useCreateQuestion = () => {
    const [ isLoading, setIsLoading]         = useState(false)
    const [ selectedState, setSelectedState] = useState("-1") // index

    const [ levels, setLevels ]       = useState([])

    const [ title, setTitle ]           = useState("")
    const [ example, setExample ]       = useState("")
    const [ body, setBody ]             = useState("")
    const [ levelType, setLevelType ]   = useState("validator")
    
    const init = () => {
        LevelsAdminService.all()
        .then((data) => {
            setLevels(data)
        })
        .catch((err) => console.error(err))
    }


    const serialize = () => {
        if(selectedState === -1 || selectedState === "-1"){
            return {
                title,
                example,
                body,
                levelType
            }
        }
        else{
            return {
                id:levels[selectedState].id,
                title,
                example,
                body,
                levelType
            }
        }
    }

    const resetForm = () => {
        setSelectedState("-1")
        setTitle("")
        setExample("")
        setBody("")
        setLevelType("validator")
    }

    useEffect(() => {
        let mounted = false;

        if(!mounted){
            init()
        }

        return () => {mounted = true}
    }, [])
  
    const onSubmit = () => {
        if(selectedState === -1 || selectedState === "-1"){
            //create
            LevelsAdminService.create(serialize())
            .then((res) => {
                setLevels((prev) => [res, ...prev])
                resetForm()
            })
            .catch((err) => console.error(err))
        }   
        else{
            // edit
            LevelsAdminService.patch(serialize().id, serialize())
            .then((res) => {
                const temp = levels.map((lvl) => {
                    if(lvl.id === res.id){
                        return res
                    }
                    return lvl
                })
                setLevels((prev) => temp)
                // resetForm()
            })
            .catch((err) => console.error(err))

        }
    }

    const onDebug = () => {
        console.log(levels)
    }

    const onRemoveQuestion = () => {
        if(selectedState !== "-1" && serialize().id){
            LevelsAdminService.destroy(serialize().id)
            .then(() => init())
            .catch((err) => console.error(err))
        }
    }
    
    const onSelectedStateChange = (value) => {
        if(value === -1 || value === "-1"){ 
            setSelectedState(value)
            resetForm() 
        }
        else{
            setTitle(levels[value]?.title)
            setBody(levels[value].body)
            setExample(levels[value]?.example ? levels[value]?.example : "")
            setLevelType(levels[value].levelType)
        }


        setSelectedState(value)
    }

    return {
        levels,
        onRemoveQuestion,
        onDebug,
        isLoading,
        onSubmit,
        selectedState,
        setSelectedState,
        title, 
        body, 
        example,
        levelType, 
        setTitle,
        setBody,
        setExample,
        onSelectedStateChange,
        setLevelType,
    }
}