// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { addDoc, collection, deleteDoc, doc, getDocs, getFirestore, updateDoc } from 'firebase/firestore'
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBT5kT5LtYFzOhYODaWlp9lc8n7r8pUyr8",
  authDomain: "python-app-cd4cb.firebaseapp.com",
  projectId: "python-app-cd4cb",
  storageBucket: "python-app-cd4cb.appspot.com",
  messagingSenderId: "28199669996",
  appId: "1:28199669996:web:cf9f41a08cd1f111af55c9",
  measurementId: "G-8ZR7K4900E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const getLevelPath = (level="level-one") => {
    return `main/${level}/questions`
}

const all = async (level="level-one") => {
    // try {
    //     const questionsCol = collection(FirebaseService.db, getLevelPath(level));
    //     const questionsSnapshot = await getDocs(questionsCol);
    //     return questionsSnapshot.docs.map(doc => {
    //         const { id } = doc
    //         const data = doc.data() 
    //         return { id, ...data }
    //     })
    // } catch (error) {
    //     throw Error(error)
    // }
}

const update = async (id, data, level="level-one") => {
    try {
        const docRef  = doc(db, `/main/${level}/questions/`, id)
        await updateDoc(docRef, {
            ...data
        })

        return await all(level)
    } catch (error) {
        throw Error(error)
    }
}

const create = async (data, level="level-one") => {
    try {
        addDoc(collection(FirebaseService.db, getLevelPath(level)),{
            ...data
        })

        return await all(level)
    } catch (error) {
        throw Error(error)
    }
}

const remove = async (id, level="level-one") =>{
    try {
        const docRef  = doc(db, `/main/${level}/questions/`, id)
        await deleteDoc(docRef)
        return await all(level)
    } catch (error) {
        throw Error(error)
    }
}

const FirebaseService = {
    db,
    all,
    getLevelPath,
    update,
    create,
    remove
}

export default FirebaseService
// const analytics = getAnalytics(app);