import { Link, useNavigate } from "react-router-dom"
import UserService from "../api/services/UserService"
import { useState } from "react"



const LoginPage = () => {
    const navigate    = useNavigate()
    const [username, setUsername]   = useState("")
    const [password, setPassword]   = useState("")
    const [isLoading, setIsloading] = useState(false)
    const [errorMsg, setErrorMsg]   = useState("")

    const reset = () => {
        setUsername("")
        setPassword("")
        setErrorMsg("")
    }

    const onSubmit = () => {
        setErrorMsg("")
        setIsloading(true)
        UserService.login(username, password)
        .then(() => {
            reset()

            setTimeout(() => {
                navigate('/')
            }, 1000);
        })
        .catch((e) => setErrorMsg("اسم المستخدم او كلمة السر غير صحيحة"))
        .finally(() => setIsloading(false))
    }

    return(
        <div className="h-full grid place-items-center">
            <div className="w-96 rounded-lg border border-gray-300 shadow-md p-2 mt-24 lg:p-6">
                <div className="my-6">
                    <h5 className="text-center text-xl font-medium text-gray-900">Sensor.SA</h5>
                </div>


                <div className="space-y-10x">
                    <div className="">
                        <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900">الإيميل</label>
                        <input dir="auto" onChange={(e) => setUsername(e.target.value)} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                    </div>
                    <div className="mt-3">
                        <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900">كلمة السر</label>
                        <input dir="auto" onChange={(e) => setPassword(e.target.value)} type="password" id="password" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>

                    <div className="mt-1 mb-4">
                        <Link to="/reset-password">
                            <div className="ml-auto text-xs text-blue-700 cursor-pointer select-none hover:underline">إعادة تعين كلمة السر</div>
                        </Link>
                        <div className="text-xs text-red-700">
                            {/* <div>{errors.username}</div>
                            <div>{errors.password}</div> */}
                            {errorMsg}
                        </div>
                    </div>

                    
                    <div role={"button"} onClick={() => navigate('/signup')} className="text-center underline text-blue-700 select-none">
                        تسجيل حساب
                    </div>

                    <button disabled={isLoading} onClick={() => onSubmit()} type="submit" className="w-full mt-8 text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">تسجيل الدخول</button>
                </div>
            </div>
        </div>
    )
}

export default LoginPage