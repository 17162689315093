import { Link, useNavigate } from "react-router-dom"
import Modal from 'react-modal'
import ProfileModal from '../components/modals/ProfileModal';
import { useState } from "react";
import { List, UserCircle } from "phosphor-react";
import SensorSALogoB  from '../images/sensor.sa-black-text.png'
import useLocalStorageState from "use-local-storage-state";


const Navbar = () => {
    const navigator = useNavigate()
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
    const [username, setUsername] = useLocalStorageState('username', {
        defaultValue: "",
        storageSync:true,
    })

    return(
        <div className="w-full select-none text-black bg-white border-b p-4 flex justify-between items-center max-w-6xl m-auto">
            <div className="md:w-2/6 ">
                <ul className="hidden md:flex justify-around rtl:space-x-reverse space-x-15 md:text-lg lg:text-xl">
                    <Link to={'/'}><li className="cursor-pointer">الصفحة الرئيسية</li></Link>
                    <Link to={'/achievements'}><li className="cursor-pointer">الإنجازات</li></Link>                    
                </ul>
            </div>
            <div className="grow w-3/4 md:w-2/6 flex justify-center">
                <Link to={'/'}><img className="" src={SensorSALogoB} alt="sensor.sa logo"/></Link>
            </div>
            <div className="md:w-2/6">
                <ul className="hidden md:flex justify-around rtl:space-x-reverse space-x-15 md:text-lg lg:text-xl">
                    <Link to={'/how-to-use'}><li className="cursor-pointer">طريقة الإستخدام</li></Link>
                    {
                        username !== "" ?
                        <Link to={`/profile/${username}`}><li className="cursor-pointer">{username}</li></Link>
                        :
                        <Link to={`/login`}><li className="cursor-pointer">{"تسجيل الدخول"} {username.toString()}</li></Link>
                    }
                </ul>

                {/* <button className="md:hidden">
                    <List  size={32}/>
                </button> */}
            </div>
        </div>
    )
}

export default Navbar


// return(
//     <div className="w-full select-none text-white bg-blue-600 p-4 flex flex-row-reverse justify-between items-center">
//         <div className="flex flex-row-reverse items-center space-x-reverse space-x-8">
//             <div onClick={() => navigator('/')} className="text-4xl font-bold">
//                 Python
//             </div>
//             <div>
                // <ul className="underline">
                //     <li className="cursor-pointer">عن المبادرة</li>
                // </ul>
//             </div>
//         </div>


//         <button onClick={() => setIsProfileModalOpen(true)}>
//             <UserCircle size={32} /> 
//         </button>
        
//         <Modal className="max-w-xl bg-white p-2 rounded m-auto md:mt-36" isOpen={isProfileModalOpen} onRequestClose={() => setIsProfileModalOpen(false)}>
//             <ProfileModal onClose={() => setIsProfileModalOpen(false)}/>
//         </Modal>
//     </div>
// )