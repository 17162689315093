import { useRef } from "react"
import { usePy } from "../context/PyContext"
import { FormatOutputMessege } from "../utils"
import OutputMessageComponent from "./OutputMessageComponent"
import { FloppyDisk, Spinner } from "phosphor-react"
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/theme-twilight";


const CodeBox = (props) => {
    const inputRef = useRef(undefined)


    const onInputKeyPressed = (e) => {
        if(e.keyCode === 9){
            e.preventDefault()
            // stackoverflow code #38385936
            const val = props.inputValue;
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            props.setInputValue(`${val.substring(0, start)}\t${val.substring(end)}`)
            setTimeout(() => {
                inputRef.current.selectionStart = inputRef.current.selectionEnd = start + 1
            }, 1)
        }
        if(e.keyCode === 13 & e.ctrlKey){
            props.onRun(props.inputValue)
        }
    }

    return(
        <div className="h-full">
            <div className="flex flex-col h-full relative">
                <div className='w-full p-1 flex justify-between items-center flex-row pr-2 pl-2 bg-transparent'>
                    <div className='flex items-center space-x-2'>
                        {/* <button onClick={() => props.submitAnswer(props.inputValue)}>SEND</button> */}
                        <div className={`${props.isProgLoading === true ? "animate-spin block duration-100" : "hidden"}`}>
                            <Spinner size={32} color="black"/>
                        </div>
                    </div>

                    <div className='flex items-center p-1- space-x-reverse space-x-4 h-10'>
                        <button className="text-sm" onClick={() => props.aiAssistant()}>مساعدة</button>
                        <div onClick={() => props.setInputValue("")} onDoubleClick={props.clearOutput} className="select-none cursor-pointer text-sm ">مسح</div>
                        <div onClick={() => props.onRun(props.inputValue)} className='bg-green-700 px-6 py-1 text-sm text-white m-auto rounded-lg cursor-pointer select-none'>تشغيل</div>
                    </div>
                </div>

                {/* <textarea 
                    dir="ltr"
                    ref={inputRef}
                    spellCheck="false"
                    autoComplete="false"
                    className="w-full h-full grow p-2 text-2xl bg-transparent border-black border-y-2 focus:outline-none" 
                    type="text"
                    value={props.inputValue}
                    onKeyDown={(e) => onInputKeyPressed(e)}
                    onChange={(e) => props.setInputValue(e.target.value)}
                /> */}

                <AceEditor
                    className="w-full h-full grow p-2 -bg-[#1c2130]-"
                    width="100%"
                    height="100%"
                    mode="python"
                    // theme="twilight"
                    onChange={(value, event) => props.setInputValue(value)}
                    value={props.inputValue}
                    fontSize={20}
                    lineHeight={20}
                    showPrintMargin={!true}
                    showGutter={true}
                    highlightActiveLine={true}
                    style={{
                        // color: 'white',
                        // backgroundColor: '#1c2130',
                        // fontFamily: 'droid-sans-mono'
                    }}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 4,
                    }}
                    markers={[{ startRow: 0, startCol: 2, endRow: 1, endCol: 5, className: 'error-marker', type: 'background' }]}
                />
         
                
                <div dir="ltr" className='m-0 p-2 h-28 grow bg-transparent overflow-y-auto border-t border-black'>
                    {props?.output?.map((outputLog, index) => (
                        <div key={index} className="text-xl" >
                            <OutputMessageComponent {...outputLog} />   
                        </div>
                    ))}
                </div>

                <div className={`w-[100%] bg-red-500- h-44 z-50 bottom-0 bg-white border border-black border-b-0 rounded-t-lg px-2 -mb-1 mx-0 transition duration-200 ease-out ${props.showAssistantBox ? 'absolute' : 'hidden'}`}>
                    <div className="flex justify-between pt-2 pb-1 border-b border-stone-200 mb-1">
                        <div className="font-semibold text-lg text-stone-700 select-none">مساعدة</div>
                        <button className="select-none rounded-full border border-stone-500 aspect-square w-7 h-7" onClick={() => props.closeAiAssistantBox()}>X</button>
                    </div>
                    <div>
                        {
                            props.isAssistantLoading ? 
                            <div className="flex justify-center items-center">
                                <Spinner className="animate-spin block duration-100" size={32} color="black"/>
                            </div>
                            :
                            <div className="text-blue-500">
                                <p>{props.assistants?.text}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
} 


export default CodeBox