import ReactMarkdown from "react-markdown"
import remarkBreaks from "remark-breaks"
import remarkGfm from "remark-gfm"


const TutorialBox = (props) => {

    return(
        <div className="h-full flex flex-col">
            <div className='w-full mb-0 border-b-2 border-black flex h-12'>
                <select dir='rtl' value={props.courseActiveLevel?.id} defaultValue={undefined} onChange={(e) => props.onActiveLevelChange(e.target.value)} className='w-full p-2 text-xl font-bold grow focus:outline-none'>
                    {
                        props.course?.material?.length > 0 ?
                        props.course?.material?.map((lvl) => (
                            <option value={lvl.id} key={lvl.id} className=''>
                                {`${lvl.chapter || 0}.${lvl.section || 0} ${lvl.level.title}`} {lvl.isCompleted ? "✔" : ""}
                            </option>
                        ))
                        : 
                        <option disabled value={undefined} className="test-center">لا توجد اسئلة حاليا</option>
                    }
                </select>

                <div className='flex'>
                    <div onClick={() => props.setPreviousLevel()} className='w-14 aspect-square  select-none cursor-pointer flex items-center justify-center text-2xl m-0'>{`<`}</div>
                    <div onClick={() => props.setNextLevel()} className='w-14 aspect-square select-none cursor-pointer flex items-center justify-center text-2xl m-0'>
                        <div>
                            {`>`}
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col grow relative overflow-y-auto h-full'>
                <div className='grow flex flex-col mb-3'>
                    <div className='w-full grow text-2xl p-2 bg-transparent h-full' dir='rtl'>
                        <ReactMarkdown 
                            // rehypePlugins={[rehypeHighlight, remarkGfm]} 
                            rehypePlugins={[remarkBreaks, remarkGfm]} 
                            className="text-2xl p-2" 
                            children={props?.courseActiveLevel?.level?.body}
                            components={{
                                em: ({node, ...props}) => <div className='text-center' {...props} />,
                                code({node, inline, className, children, ...props}) {
                                    return(
                                        <span dir="ltr" className={`bg-[#f4f4f4] border border-[#ddd] text-[#666] break-inside-avoid break-words w-fit px-2 py-1 font-mono ${className}`} {...props}>
                                            {children}
                                        </span>
                                    )
                                }
                                
                            }}
                        />
                    </div>
                    {/* <div className={`bg-gray-400 p-2 my-4 mx-4 rounded ${levels[activeLevel]?.level_goals?.length > 0 ? "block" : "hidden"}`}>
                        {
                            levels[activeLevel]?.level_goals?.map((goal) => (
                                <div key={goal.id || goal.title} className='flex space-x-reverse space-x-3 select-none'>
                                    <input className='w-9 disabled:bg-white cursor-not-allowed' type={'checkbox'} disabled />
                                    <div>{goal.title}</div>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
                
                {/* <div className={`sticky bottom-0 w-full mb-0 select-none ${levels[activeLevel]?.level_goals?.length > 0 ? "block" : "hidden"}`}>
                    <div className='flex w-full items-center justify-center'>
                        <div className='flex items-center space-x-reverse space-x-2 bg-white px-8 py-2 rounded-tl-md rounded-tr-md font-bold'>
                            <div>0%</div>
                            <progress style={{height:'5px'}} dir='rtl' value={0}  max={100}/>
                            <div role={"button"} className='rounded-full select-none border-2 border-black hidden' style={{padding:'3px', aspectRatio:'9/3', textAlign:'center', fontSize:'13px'}}>؟</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default TutorialBox