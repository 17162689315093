import { v4 as uuid } from 'uuid'
import { useMemo, useState } from "react"
import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import remarkGfm from 'remark-gfm'
import { usePy } from '../context/PyContext'

const LevelEditorPage = () => {
    const {run, output, lux } = usePy()
    const [selectedStateIndex, setSelectedStateIndex] = useState(0)
    const [levelContent, setLevelContent] = useState("")    
    const [code, setCode] = useState(`print("h")`)
    const [codeRes, setCodeRes] = useState("")
    const [luxOutput, setLuxOutput] = useState("")
    
    const [state, setState] = useState([])
    const [newItemValue, setNewItemValue] = useState("")
    const [selectedState, setSelectedState] = useState(undefined)
    const [levelGoal, setLevelGoal] = useState([])
    const [levelExample, setLevelExample] = useState("") 

    const onNewItemAdd = () => {
        // if(newItemValue.length > 1){
        //     const newItem = {}
        //     newItem.id = uuid()
        //     newItem.title = newItemValue
        //     setNewItemValue("")
        //     const temp = [...state]
        //     temp.push(newItem)
        //     // setState((prev) => prev.push(newItem) )
        //     setState((prev) => temp )
        //     console.log('ttt', typeof(state));
        // }   
    }


    // useEffect(() => {
    //     const obj = state.find((x) => x.id === selectedState)
    //     const index = state.indexOf(obj)
    //     setSelectedStateIndex(index)
    // }, [selectedState])

    // const selectedObj = useMemo(() => state.find((x) => x.id === selectedState), [state, selectedState])
    // const selectedObj = undefined

    const onContentChagne = (e) => {
        const obj = state.find((x) => x.id == selectedState)
        const index = state.indexOf(obj)

        obj.content = e

        if(index != -1){
            const temp = state 
            temp[index] = obj
            setState((prev) => temp)
        }
        else{
            console.log('index not valid');
        }
    }

    // const objPreview = useMemo(() => {
        
    // }, [selectedStateIndex])

    const onRun = () => {
        run(code)
        
        const luxRes = lux(code)
        console.log('LuxRes=', luxRes);
        setLuxOutput(JSON.stringify(luxRes, null, 2))
    }

    return(
        <div className="h-screen">
            <div>LevelEditorPage</div>
            
            <div className="w-full flex flex-row-reverse p-2 items-center space-x-reverse space-x-4 bg-gray-300">
                <div className="space-x-2">
                    <button onClick={onNewItemAdd} className="bg-blue-500 hover:bg-blue-700 px-3 p-1 text-white rounded-md">
                        اضافة
                    </button>

                    <input dir="auto" className="p-1 px-3" value={newItemValue} onChange={(e) => setNewItemValue(e.target.value)}/>
                    <label htmlFor="">الاسم</label>
                </div>
                
                <div className="">
                    <select value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                        {state.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.title}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="flex max-w-7xl h-3/4 m-auto space-x-7 ">
                <div className="w-1/2">
                    <div className="text-xl text-center">View</div>
                    {/* <textarea dir="auto" className="w-full h-full p-2 bg-gray-300"/> */}
                    <div dir='auto' className="w-full h-full p-2 bg-gray-300 overflow-auto">
                        
                        <ReactMarkdown rehypePlugins={[rehypeHighlight, remarkGfm]} children={levelContent}/>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="text-xl text-center">Source</div>
                    <textarea dir="auto" className="w-full h-full p-2 bg-gray-300" value={levelContent} onChange={(e) => setLevelContent(e.target.value)}/>
                    
                    

                    

                </div>
            </div>

            <div className='mt-12 m-auto max-w-7xl flex items-center justify-center'>
                <label htmlFor="">code:</label>
                <textarea className='grow bg-gray-300' value={code} onChange={(e) => setCode(e.target.value)}/>
            </div>
            
            <div className='max-w-7xl flex justify-center m-auto mt-4'>
                <button onClick={() => onRun()} className='bg-green-500 px-8 rounded py-0 text-white'>RUN</button>
            </div>

            <div className='mt-12 m-auto max-w-7xl flex overflow-x-scroll items-start '>
                <div className='flex w-1/2 bg-gray-300 h-full'>
                    {/* <label htmlFor="">Res:</label> */}
                    {/* <textarea className='grow bg-gray-300' value={output} onChange={(e) => setCodeRes(e.target.value)}/> */}
                    <pre className='whitespace-pre-wrap h-full'>
                        {output.map((log) => (
                            <div>{log}</div>
                        ))}
                    </pre>
                </div>

                <div className='w-1/2 h-full bg-gray-300'>
                    {/* <label htmlFor="">lux:</label> */}
                    <pre className=''>
                        {luxOutput.msg}
                    </pre>
                </div>
            </div>
        </div>
    )
}

export default LevelEditorPage