import { Client } from "colyseus.js";
import { createContext, useContext, useEffect, useRef, useState } from "react"


const GameOneContext = createContext();


export function GameOneContextProvider({ children }) {
    const client = useRef(undefined)
    const [room, setRoom] = useState(undefined)
    const [roomState, setRoomState] = useState(undefined)
    const [playersState, setPlayersState] = useState([])
    const [testState, setTestState] = useState(false)
    const [level, setLevel] = useState(undefined)

    const onDebug = () => {
        console.log(`room state=`, room);        
    }

    const init = async () => {
        if(client.current === undefined){
            client.current = new Client("ws://localhost:3100")
        }
    }

    const onStateChange = (newState) => {
        setRoomState((prev) => newState)
        setPlayersState(newState.players)
        setTestState((prev) => !prev)
    }

    const onNewMsg = (message) => {
        // const [cmd, msg] = message
        // switch (cmd) {
        //     case "QUEST":
        //         setLevel(msg)
        //         break;
        //     default:
        //         break;
        // }

        console.log("has new msg:", message)
    }

    const onNewQuestion = (msg) => {
        setLevel(msg)
    }

    const submitAnswer = (answer) => {
        room.send("ANSWER", {
            level,
            answer
        })
    }
    const onRoomReady = () => {
        if(room !== undefined){
            room.send("ready")
        }
    }

    useEffect(() => {
        init()
    }, [])

    const joinRoom = async (roomID) => {
        if(client.current !== undefined && room === undefined){
            try {
                const newRoom = await client.current.joinOrCreate(roomID)
                
                newRoom.onStateChange((newState) => onStateChange(newState))
                newRoom.state.onChange = (changes) => console.log("newRoom.state.onChange", changes)
                newRoom.onLeave(() => setRoom(undefined) & setRoomState(undefined) & setLevel(undefined))
                // newRoom.onMessage = (message) => onNewMsg(message)
            
                newRoom.onMessage("QUEST", (msg) => onNewQuestion(msg))

                setRoom(newRoom)
                setRoomState(newRoom.state)
                console.log(client.current?.sessionId, "joined", room?.name)
            } catch (error) {
                console.error("error while joining a room ", error)
            }
        }   
        else{
            // run init then join the room
            init()
        }
    }

    return (
        <GameOneContext.Provider
          value={{
            onDebug,
            init,
            joinRoom,
            onRoomReady,
            room,
            roomState,
            playersState,
            level,
            submitAnswer
          }}
        >
          {children}
        </GameOneContext.Provider>
      )
}

export function useGameOne() {
    return useContext(GameOneContext);
  }