import UserService from "./services/UserService"


const loginStatus = (url, header, res) => {
    if(res.status === 403 || res.status === 401){
        UserService.removeToken()
        window.location = "/login"
    }
}









const middlewareService = {
    loginStatus
}

export default middlewareService