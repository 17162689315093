import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Navbar from "./components/Navbar"
import HomePage from "./pages/HomePage"
import LevelEditorPage from "./pages/LevelEditorPage"
import {PyContextProvider, usePy  } from "./context/PyContext"
import GameOnePage from "./pages/GameOnePage";
import { GameOneContextProvider } from "./context/GameOneContext";
// import { FirebaseContextProvider } from "./context/FirebaseContext";
import { CodeControllerContextProvider } from "./context/codeControllerContext";
import CreateQuestionPage from "./pages/CreateQuestionPage";
import NotImplementedPage from "./pages/NotImplementedPage";
import CoursePage from "./pages/CoursePage";
import LoginPage from "./pages/LoginPage";
import CreateValidatorPage from "./pages/CreateValidatorPage";
import AchievementsPage from "./pages/AchievementsPage";
import HowToUsePage from "./pages/HowToUsePage";


function App() {
    return(
        <div dir="rtl" className='h-full- m-auto'>
            <BrowserRouter>
                <div className='h-full- w-full- relative '>
                    <PyContextProvider>
                        <GameOneContextProvider>
                            <CodeControllerContextProvider>
                                {/* <FirebaseContextProvider> */}
                                    <div className='md:flex md:flex-col'>
                                        <Navbar />
                                        <div className='p-2 grow h-full'>
                                            <Routes>
                                                <Route path="/" index element={<HomePage />} />
                                                <Route path="/course/:courseID" element={<CoursePage />} />
                                                <Route path="/how-to-use" element={<HowToUsePage />} />
                                                <Route path="/achievements" element={<AchievementsPage />} />
                                                <Route path="/not-implemented" element={<NotImplementedPage />} />
                                                <Route path="/new-validator" element={<CreateValidatorPage />} />
                                                <Route path="/new-question" element={<CreateQuestionPage />} />
                                                <Route path="/edit" element={<LevelEditorPage />} />
                                                <Route path="/game1" element={<GameOnePage />} />
                                                <Route path="/login" element={<LoginPage />} />
                                            </Routes>
                                        </div>
                                    </div>
                                {/* </FirebaseContextProvider> */}
                            </CodeControllerContextProvider>
                        </GameOneContextProvider>
                    </PyContextProvider>
                </div>
            </BrowserRouter>
        </div>
    )
}

export default App;
