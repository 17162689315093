import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useNavigate } from 'react-router-dom'
import { useCodeController } from '../../context/codeControllerContext'
import useLocalStorageState from 'use-local-storage-state'

const ProfileModal = (props) => {
    const navigator = useNavigate()
    const { levels, solved, resetProgress } = useCodeController()
    const [username, setUsername] = useLocalStorageState('local_username', {
        defaultValue: ""
    })

    const onNewQuestionClick = () => {
        navigator("/new-question")
        props.onClose()
    }

    const onNameChange = (s) => {
        if(s === "F01") { localStorage.setItem("admin", 1) }
        if(s === "F02") { localStorage.removeItem("admin") }
        setUsername(s)
    }
    
    return(
        <div className='md:px-4 mb-8'>
            <div className="flex p-1 border-b">
                <button onClick={() => props.onClose()}>X</button>
            </div>

            <div>
                <div className="flex justify-between w-full pt-5">
                    <div className='w-[100px] h-[100px]'>
                        <CircularProgressbar minValue={0} maxValue={levels.length || 0} value={solved.length || 0} text={`${((solved.length || 1) / (levels.length || 1)) * 100|| 0}%`} />
                    </div>
                    <div className="space-y-1">
                        <div className='text-right'>الإسم</div>
                        <input value={username} onChange={(e) => onNameChange(e.target.value)} dir="auto" className="placeholder:text-right placeholder:px-2 focus:outline-none border-black border p-1"/>
                    </div>
                </div>
            </div>

            <div className={`pt-5 text-center text-sm ${localStorage.getItem('admin') !== null ? "block" : "hidden"}`}>
                <button onClick={() => onNewQuestionClick()} className={`text-blue-500 underline font-medium `}>سؤال جديد</button>
            </div>
            <div className={`pt-5 text-center text-sm`}>
                <button onClick={() => resetProgress()} className='text-red-500 font-medium'>اعادة تعين</button>
            </div>
        </div>
    )
}

export default ProfileModal