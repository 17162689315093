import { CircleWavyWarning } from "phosphor-react"
import { useNavigate } from "react-router-dom"


const NotImplementedPage = ({source}) => {
    const navigator = useNavigate()

    return(
        <div>
            <CircleWavyWarning className="m-auto pt-8" color="black" size={128} />

            <h1 className="text-center">الميزة غير متاحة حاليا</h1>
            <div className="text-center mt-10">
                <div>
                    <p>
                        الميزة غير متاحة في الإطلاق التجريبي
                    </p>
                </div>
                <div className="pt-4">
                    <a role="button" href="http://wa.me/966554795244" className="border px-8 py-1 my-4">تواصل معنا</a>
                </div>
                <br />
                <button onClick={() => navigator(-1)} className="px-8 py-1 my-4">رجوع</button>
            </div>
        </div>
    )
}

export default NotImplementedPage