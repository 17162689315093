import { Link } from "react-router-dom"



const AdminToolkit = ({ishidden}) => {

    return(
        <div hidden className={`${ishidden ? 'hidden' : 'flex' }  justify-center m-auto max-w-lg items-center space-x-4 rtl:space-x-reverse bg-yellow-500 p-4 rounded-lg border-yellow-700 border-dashed border-4`}>
            <Link className="px-7 py-1.5 bg-red-500 rounded-lg text-white" to={'/new-question'}>New question</Link>
            <Link className="px-7 py-1.5 bg-red-500 rounded-lg text-white" to={'/new-validator'}>New validator</Link>
        </div>
    )
}

export default AdminToolkit