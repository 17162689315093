import { useState } from "react"
import CourseService from "../api/services/CourseService"
import { useEffect } from "react"


export const useCourseService = (page, limit) => {
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")


    const init = () => {
        setIsLoading(true)
        setErrorMsg("")
        CourseService.all(1, 99)
        .then((res) => {
            console.log(res)
            setCourses(res)
        })
        .catch((e) => setErrorMsg(e.message))
        .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        init()
    }, [])

    return {
        courses,
        isLoading,
        errorMsg
    }
}