import { useEffect, useMemo, useState } from "react"
import useLocalStorageState from 'use-local-storage-state'
import { usePy } from "../context/PyContext"
import CourseService from "../api/services/CourseService"
import pythonParser from "../parser"



export const useCourseControler = (courseID) => {
    const [isLoading, setIsLoading]       = useState()
    const [isAssistantLoading, setIsAssistantLoading] = useState(true)
    const [errorMsg, setErrorMsg]         = useState("")
    const [course, setCourse]             = useState()

    const [isControllerLoading, setIsControllerLoading] = useState(false)
    const [isProgLoading, setIsProgLoading] = useState(false)
    const [inputValue, setInputValue]     = useState(``)
    const [activeLevel, setActiveLevel]   = useState(0) //ID
    const [isAnonUser, setIsAnonUser]     = useState(false)
    const [showAssistantBox, setShowAssistantBox] = useState(false)
    const [assistants, setAssistants]     = useState()
    const [solved, setSolved]             = useLocalStorageState('solved', {
        defaultValue: []
    })

    const { run, displayLevelCompleteMsg, sendMsg } = usePy()

    useEffect(() => {
        let mounted = false

        if(!mounted){
            setIsLoading(true)
            setErrorMsg("")
            CourseService.get(courseID)
            .then((data) => {
                setCourse(data)
                let onLoadID = data?.material?.find((lvl) => !lvl.isCompleted)?.id
                if(!onLoadID && data?.material?.length > 0) { 
                    onLoadID = data?.material[0].id
                }

                setActiveLevel(onLoadID)
            })
            .catch((e) => setErrorMsg(e.message))
            .finally(() => setIsLoading(false))
        }
    }, [])

    const courseActiveLevel = useMemo(() => {
        if(course?.material){
            return course.material.find((lvl) => lvl.id == activeLevel)
        }
        return undefined
    }, [activeLevel, course])


    const lux = (s) => {
        return pythonParser.parse(s)
    }

    const onLevelDone = (activeLevelCopy, answer, validationResults) => {
        // !solved.includes(levels[activeLevel]?.id
        const temp = course
        temp.material = temp.material.map((lvl) => {
            if(lvl.id === activeLevelCopy.id){
                lvl.isCompleted = validationResults.isCorrect
            }
            return lvl
        })
        
        setCourse(Object.assign({}, temp))
        
        if(activeLevelCopy.isCompleted === false){
            displayLevelCompleteMsg(`تم الانتهاء من ${activeLevelCopy.level.title}`)
        }
        if(!isAnonUser && validationResults?.anon === true){
            setIsAnonUser(validationResults?.anon)
            sendMsg('critical', 'سجل دخول لحفظ مستوى التقدم')
        }
        
        setSolved((prev) => [...prev, activeLevelCopy.id])
        setNextLevel()
    }

    const onRun = async (answer) => {
        try {
            setIsProgLoading(true)
            await run(answer)
            if(!courseActiveLevel){ return }
            const activeLevelCopy = Object.assign({}, courseActiveLevel)  

            const validationResults = await CourseService.validate(answer, activeLevelCopy.id)
            console.log(validationResults)
            
            if(validationResults.isCorrect){
                onLevelDone(activeLevelCopy, answer, validationResults)
            }
        } catch (error) {
            console.error(error.message)
            // sendMsg("error", 'حدث خطأ اثناء حفظ مستوى التقدم يرجى المحاولة لاحقا')
        } finally{
            setIsProgLoading(false)
        }
    }

    const onActiveLevelChange = (id) => {
        setActiveLevel(id)
    }

    const setNextLevel = () => {
        if(!course.material.length > 0 ){ return }
        if(courseActiveLevel){
            let currentIndex = course?.material.findIndex((lvl) => lvl.id === courseActiveLevel.id)
            const nextIndex = ++ currentIndex % course?.material.length 
            setActiveLevel(course?.material[nextIndex].id)
        }
        else{
            setActiveLevel(course?.material?.find((lvl) => !lvl.isCompleted)?.id)
        }
    }

    const setPreviousLevel = () => {
        if(!course.material.length > 0 ){ return }
        const length = course.material.length;
        let currentIndex = course?.material.findIndex((lvl) => lvl.id === courseActiveLevel.id)
        setActiveLevel(course.material[(currentIndex + length - 1) % length].id) 
    }
    
    const aiAssistant = async () => {
        if(showAssistantBox) { return }
        if(!courseActiveLevel?.id) { return }
        if(inputValue?.length < 5){ 
            return sendMsg('warning', 'قم بكتابة كود للحصول على مساعدة')
        }

        setShowAssistantBox(true)
        setIsAssistantLoading(true)
        CourseService.assistants(
            inputValue,
            courseActiveLevel?.id
        )
        .then((res) => setAssistants(res))
        .catch((err) => console.error(err))
        .finally(() => setIsAssistantLoading(false))
    }

    const closeAiAssistantBox = () => setShowAssistantBox(false)

    return{
        isLoading,
        errorMsg,
        course,
        // levels,
        isControllerLoading,
        courseActiveLevel,
        isProgLoading,
        onRun,
        inputValue, 
        setInputValue,
        onActiveLevelChange,
        setNextLevel,
        setPreviousLevel,
        isAnonUser,
        showAssistantBox,
        closeAiAssistantBox,
        aiAssistant,
        isAssistantLoading,
        assistants,
        debug:{
            activeLevel
        }
    }
}