/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable strict */
/* eslint-disable no-use-before-define */
/* eslint-disable no-redeclare */

var pythonParser = /*
* Generated by PEG.js 0.10.0.
*
* http://pegjs.org/
*/
(function() {
 "use strict";

 function peg$subclass(child, parent) {
   function ctor() { this.constructor = child; }
   ctor.prototype = parent.prototype;
   child.prototype = new ctor();
 }

 function peg$SyntaxError(message, expected, found, location) {
   this.message  = message;
   this.expected = expected;
   this.found    = found;
   this.location = location;
   this.name     = "SyntaxError";

   if (typeof Error.captureStackTrace === "function") {
     Error.captureStackTrace(this, peg$SyntaxError);
   }
 }

 peg$subclass(peg$SyntaxError, Error);

 peg$SyntaxError.buildMessage = function(expected, found) {
   var DESCRIBE_EXPECTATION_FNS = {
         literal: function(expectation) {
           return "\"" + literalEscape(expectation.text) + "\"";
         },

         "class": function(expectation) {
           var escapedParts = "",
               i;

           for (i = 0; i < expectation.parts.length; i++) {
             escapedParts += expectation.parts[i] instanceof Array
               ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
               : classEscape(expectation.parts[i]);
           }

           return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
         },

         any: function(expectation) {
           return "any character";
         },

         end: function(expectation) {
           return "end of input";
         },

         other: function(expectation) {
           return expectation.description;
         }
       };

   function hex(ch) {
     return ch.charCodeAt(0).toString(16).toUpperCase();
   }

   function literalEscape(s) {
     return s
       .replace(/\\/g, '\\\\')
       .replace(/"/g,  '\\"')
       .replace(/\0/g, '\\0')
       .replace(/\t/g, '\\t')
       .replace(/\n/g, '\\n')
       .replace(/\r/g, '\\r')
       .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
       .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
   }

   function classEscape(s) {
     return s
       .replace(/\\/g, '\\\\')
       .replace(/\]/g, '\\]')
       .replace(/\^/g, '\\^')
       .replace(/-/g,  '\\-')
       .replace(/\0/g, '\\0')
       .replace(/\t/g, '\\t')
       .replace(/\n/g, '\\n')
       .replace(/\r/g, '\\r')
       .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
       .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
   }

   function describeExpectation(expectation) {
     return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
   }

   function describeExpected(expected) {
     var descriptions = new Array(expected.length),
         i, j;

     for (i = 0; i < expected.length; i++) {
       descriptions[i] = describeExpectation(expected[i]);
     }

     descriptions.sort();

     if (descriptions.length > 0) {
       for (i = 1, j = 1; i < descriptions.length; i++) {
         if (descriptions[i - 1] !== descriptions[i]) {
           descriptions[j] = descriptions[i];
           j++;
         }
       }
       descriptions.length = j;
     }

     switch (descriptions.length) {
       case 1:
         return descriptions[0];

       case 2:
         return descriptions[0] + " or " + descriptions[1];

       default:
         return descriptions.slice(0, -1).join(", ")
           + ", or "
           + descriptions[descriptions.length - 1];
     }
   }

   function describeFound(found) {
     return found ? "\"" + literalEscape(found) + "\"" : "end of input";
   }

   return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
 };

 function peg$parse(input, options) {
   options = options !== void 0 ? options : {};

   var peg$FAILED = {},

       peg$startRuleFunctions = { file_input: peg$parsefile_input },
       peg$startRuleFunction  = peg$parsefile_input,

       peg$c0 = function(prog) { return { 
           type: "Program", 
           body: prog
               .filter(x=>x!==null)
               .map(x=>x==="\n"?{type:"BlankLine"}:x) 
         } 
       },
       peg$c1 = function(e) { return { type: "Decorator", expr: e }; },
       peg$c2 = function(d) { return {type: "Decorators", decorators: d }; },
       peg$c3 = function(d, s) { 
           return { type: "DecoratedCompoundStatement", decorators: d, stmt: s };
       },
       peg$c4 = function(f) { return { type: "AsyncFunctionDefinition", funcdef: f }; },
       peg$c5 = function(n, p, tC, b) {
           if(typeof t === "undefined") var t = "";
           return {
               type: "FunctionDefinition",
               parameters: p,
               typeType: t || tC,
               body: b,
               name: n
           }
       },
       peg$c6 = function(params) { 
         if(typeof params === "undefined") var params = [];
         if(params === null) params = [];
         return { 
           type: "Parameters", 
           params: params.filter(x=>x)
         }; 
       },
       peg$c7 = function(head, tail) { 
           if(typeof tail === "undefined") {
               var tail = [];
           }
           return { type: "Arguments", arguments: [head].concat(tail.map(x=>x[2])) };
       },
       peg$c8 = function(a) { return { type: "KwArg", arg: a } },
       peg$c9 = function(a) { return { type: "StarArg", arg: a }; },
       peg$c10 = function(a) { return a; },
       peg$c11 = function(t) { return t; },
       peg$c12 = function(n, t) {
           if(t && t.length > 0) return { type: "TypedArgument", typeType: t[1], name: n };
           else return n;
       },
       peg$c13 = function(a, v) { 
                   return {
                       type: "VarArgsList",
                       args: a,
                       varargs: v && v[1]
                   }
               },
       peg$c14 = function(v) { return v; },
       peg$c15 = function(s, com) { if(com) return { type: "CommentedStatement", comment:com, statement: s }; else return s; },
       peg$c16 = function(head, tail) { 
           if(tail && tail.length) return { type: "StatementList", list: [head].concat(tail.map(x=>x[1])) }; 
           else return head 
       },
       peg$c17 = function(s) { return s; },
       peg$c18 = function(expr, y) { return y; },
       peg$c19 = function(expr, ass, t) { if(t) return { type:"TypedAssignment", type: t, assignment: ass }; else return ass;  },
       peg$c20 = function(expr, asgn) {
                            if(asgn)
                            return {
                                type: "AssignmentExpressionStatement",
                                expr: expr,
                                asgn: asgn[0]
                            }
                            else return {
                                type: "ExpressionStatement",
                                expr: expr
                            }
                            },
       peg$c21 = function(head, tail) {
           if(tail && tail.length > 0) return {
               type: "TestList",
               list: [head].concat(tail.map(x=>x[1]))
           };
           else return head;
       },
       peg$c22 = function(o) { return o;  },
       peg$c23 = function(e) { return { type: "DelStatement", exprlist: e } },
       peg$c24 = function() { return { type: "PassStatement" } },
       peg$c25 = function() { return { type: "BreakStatement" } },
       peg$c26 = function() { return { type: "ContinueStatement" } },
       peg$c27 = function(expr, ctx) { return { type: "ExecStatement", value: expr, ctx: ctx?ctx[1]:ctx } },
       peg$c28 = function(expr) { return { type:"ReturnStatement", value: expr }; },
       peg$c29 = function(expr) { return { type:"PrintStatement", value: expr }; },
       peg$c30 = function(y) { return { type: "YieldStatement", yielded: y } },
       peg$c31 = function(v) { return { type: "RaiseStatement", err: v&&v[0], context: v&&v[1]&&v[1][1] } },
       peg$c32 = function(i) { return {type: "ImportNameStatement", imports: i}; },
       peg$c33 = function(ellips, name, from) { 
           if(from.constructor == Array && from.length == 3) from = from[1];
           return {
               type: "ImportFromStatement", 
               name: name,
               imprt: from,
               ellips: ellips.join("")
           } },
       peg$c34 = function(head, tail) { if(!tail) return head; else { return { type:"DottedName", head: head, tail: tail.map(x=>x[1]) } }  },
       peg$c35 = function(head, as) { if(!as || !as[0]) return head; else return { type: "AsName", target: head, as: as} },
       peg$c36 = function(head, tail) { return [head].concat(tail.map(x=>x[1]));  },
       peg$c37 = function(head, tail) { if(tail.length == 0) return head; else { return { type:"DottedName", head: head, tail: tail.map(x=>x[1]) } } },
       peg$c38 = function(head, tail) { return { type: "GlobalStatement", names: [head].concat(tail.map(x=>x[1])) }; },
       peg$c39 = function(head, tail) { return { type: "NonlocalStatement", names: [head].concat(tail.map(x=>x[1])) }; },
       peg$c40 = function(t, a) { return { type: "AssertStatement", test: t, alt: a&&a[1] }; },
       peg$c41 = function(s) { return { type: "AsyncStatement", stmt: s }; },
       peg$c42 = function(t, b, l, e) {
           return {
               type: "IfStatement",
               body: b,
               test: t,
               elifBlocks: l,
               elseBlock: e  && e[2]
           };
       },
       peg$c43 = function(t, b) {
           return {
               type: "ElifStatement",
               test: t,
               body: b
           };
       },
       peg$c44 = function(t, b, e) {
           return {
               type: "WhileStatement",
               test: t,
               body: b,
               elseBlock: e&&e[2]
           };
       },
       peg$c45 = function(v, l, b, e) {
           return {
               type: "ForStatement",
               vars: v,
               inList: l,
               body: b,
               elseBlock: e&&e[2]
           };
       },
       peg$c46 = function(s, x, l, f) { return { type: "CatchBlock", finallyBlock: f&&f[2], elseBlock: l&&l[2], exceptBlocks: x.map(x=>x[2])  }},
       peg$c47 = function(s, f) { return { type: "CatchBlock", finallyBlock: f };},
       peg$c48 = function(s, e) {
                      return {
                          type: "TryStatement",
                       body: s,
                       catchBlock: e
                      }
                  },
       peg$c49 = function(w, s) {
               return {type: "WithStatement", body: s, withItem: w};
           },
       peg$c50 = function(head, tail) { return { type: "WithItemList", list: [head].concat(tail.map(x=>x[1])) } },
       peg$c51 = function(v, a) { 
               if(a && a.length > 0) return { type: "WithAsItem", value: v, as: a[1] } 
               else return { type: "WithItem", value: v };
           },
       peg$c52 = function(head, tail) {
           return {
               type: "ExceptClause",
               params: head?[head].concat(tail.map(x=>x[1])):[]
           }
       },
       peg$c53 = function(t, a) {
           return {
               type:"ExceptClauseParam",
               param: t,
               as: a&&a[1]
           }
       },
       peg$c54 = function(body) {
           if(body[0] == "") body = body[1];

        //    if(typeof simp !== "undefined") return simp;
           else return {
               type: "Suite",
               body: body.map(function(x) {
                   if(x[1] == "\n") return {type:"BlankLine"};
                   else return x[1][0];
               })
           }
       },
       peg$c55 = function(t, n) { 
           if(n && n.length > 0) return {type: "NamedExpression", expr: t, value: n[1]}
           else return t;
         },
       peg$c56 = function(or, conditional) {
           if(typeof conditional !== "undefined" && conditional != null)
             return { 
                 type: "ConditionalExpression", 
               condition: conditional[2],
               value: or,
               alternate: conditional[5] 
             };
         
         return or
       },
       peg$c57 = function(lambdef) {
             return {
               type: "LambdaExpression",
               lambda: lambdef
             };
       },
       peg$c58 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "BooleanComparison",
               left: head,
               operator: "or",
               right: tail.map(x=>x[1])
           }
       },
       peg$c59 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "BooleanComparison",
               left: head,
               operator: "and",
               right: tail.map(x=>x[1])
           }
       },
       peg$c60 = function(head) {
       return {
           type: "UnaryOperator",
           operator: "not ",
           value: head
       }
       },
       peg$c61 = function(c) { return c },
       peg$c62 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "Comparison",
               left: head,
               right: tail.map(x=> ({
                 operator: x[0],
                 right: x[1]
               }))
             };
       },
       peg$c63 = function(l) {
           return l;
       },
       peg$c64 = function(expr) { return { type: "StarExpression", expr: expr }; },
       peg$c65 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "OperatorExpression",
               left: head,
               right: tail.map(x=>({ type: "OperatorExpressionTail", operator: {type: "Operator", value: "|"}, value: x[1]}))
           }
       },
       peg$c66 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "OperatorExpression",
               left: head,
               right: tail.map(x=>({ type: "OperatorExpressionTail", operator: {type: "Operator", value: "^"}, value: x[1]}))
           }
       },
       peg$c67 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "OperatorExpression",
               left: head,
               right: tail.map(x=>({ type: "OperatorExpressionTail", operator: {type: "Operator", value: "&"}, value: x[1]}))
           }
       },
       peg$c68 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "OperatorExpression",
               left: head,
               right: tail.map(x=>({ type: "OperatorExpressionTail", operator: {type: "Operator", value: x[0]}, value: x[1]}))
           }
       },
       peg$c69 = function(op, head) {
           return {
               type: "UnaryOperator",
               left: head,
               value: op
           }
       },
       peg$c70 = function(p) { return p; },
       peg$c71 = function(head, tail) {
           if(tail == null || tail.length == 0) return head;
           else return {
               type: "OperatorExpression",
               left: head,
               right: [{ type: "OperatorExpressionTail", operator: {type: "Operator", value: tail[0]}, value: tail[1]}]
           }
       },
       peg$c72 = function(a, l, t) {
            var ty = a ? "AsyncValue" : "Value";
            if(t && t.length > 0) return { type: ty,value: l[0]||l, trailers: t};
            else return {type: ty, value: l[0]||l};
       },
       peg$c73 = function(v) { return { type: "ParenWrappedValue", value: v }; },
       peg$c74 = function(v) { return { type: "ListLiteral", value: v }; },
       peg$c75 = function(v) { return { type: "DictionaryOrSetLiteral", value: v }; },
       peg$c76 = function(l) {
                  return l;
              },
       peg$c77 = function(head, f) { return f; },
       peg$c78 = function(head, v) { return v },
       peg$c79 = function(head, tail) { 
           return {
               type: "ListComp",
               list: [head].concat(tail)
           }
       },
       peg$c80 = function(a) { return { type: "FunctionCallerParams", args: a }; },
       peg$c81 = function(s) { return { type: "SubscriptParams", subscripts: s }; },
       peg$c82 = function(n) { return { type: "DotProperty", property: n } },
       peg$c83 = function(head, tail) {
         return {
           type: "SubscriptList",
           list: [head].concat(x=>x[1])
         };
       },
       peg$c84 = function(l, r, s) { return { type: "Subscript", left: l, right: r, slice: s } },
       peg$c85 = function(e) { return { type: "SliceOp", expr: e} },
       peg$c86 = function(head, tail) { return { 
               type: "ExprList",
               list: [head].concat(tail.map(x=>x[1]))
           } },
       peg$c87 = function(head, tail) {
           return { 
               type: "TestList",
               list: [head].concat(tail.map(x=>x[1]))
           }
       },
       peg$c88 = function(n, p, b) {
           return {
             type: "ClassDefinition",
             name: n,
             params: p && p[1],
             body: b
           }
       },
       peg$c89 = function(head, tail) {
           return {
               type: "Arglist",
               args: [head].concat(tail.map(x=>x[1]))
           };
       },
       peg$c90 = function(left, op, right) { return { type: "ExpressionAssignmentDefaultValueArg", argument: left, defaultVal: right }; },
       peg$c91 = function(left, compFor) { 
                       if(compFor) return { type: "ForInArgument", arg: left, compFor: compFor };
                       else return { type: "Argument", arg: left}; },
       peg$c92 = function(left, op, right) { return { type: "DefaultValueArg", argument: left, defaultVal: right }; },
       peg$c93 = function(op, left) { return {type: "KwVarArg", argument: left}; },
       peg$c94 = function(op, left) { return {type: "VarArg", argument: left}; },
       peg$c95 = function() { return { type: "PositionOnlyArgsMarkerArg" }; },
       peg$c96 = function(a, t, d) {
                      var result = a;
                      if(d && d.length > 0) return { type: "DefaultArgument", default: d[1], arg: result }; 
                      if(t && t.length > 0) return { type: "TypedArgument", type: t[1], arg: result };
                      
                      return result;
                     },
       peg$c97 = function(c) { return { type: "Comment", comment: c}; },
       peg$c98 = function(i) {
             return i.length == 0 || i.length / singleIndentLen === indentLevel;
           },
       peg$c99 = function() { return ""; },
       peg$c100 = function(t) {
             if(indentLevel == 0 && t.length > 0) singleIndentLen = t.length

             var newIndent = t.length / singleIndentLen;
             var oldIndent = indentLevel;
             
             if(newIndent > oldIndent) indentLevel++; 
             return newIndent > oldIndent;
           },
       peg$c101 = function(t) { return ""; },
       peg$c102 = function(t) {
             var newIndent = t.length / singleIndentLen;
             var oldIndent = indentLevel;

             if(newIndent <= oldIndent) indentLevel--; 
             return newIndent <= oldIndent;
           },
       peg$c103 = "\t",
       peg$c104 = peg$literalExpectation("\t", false),
       peg$c105 = "  ",
       peg$c106 = peg$literalExpectation("  ", false),
       peg$c107 = function(l) {
             var val = l[0] + l[1].join("") + l[2];
             return {
                 type: "StringLiteral",
                 value: val
             };
         },
       peg$c108 = function(m) { return {type: "MultilineStringLiteral", value: m }; },
       peg$c109 = function(l) {
             return l;
         },
       peg$c110 = /^[^\\"]/,
       peg$c111 = peg$classExpectation(["\\", "\""], true, false),
       peg$c112 = /^[^\\']/,
       peg$c113 = peg$classExpectation(["\\", "'"], true, false),
       peg$c114 = "\\",
       peg$c115 = peg$literalExpectation("\\", false),
       peg$c116 = /^[\\'"abfnrtv]/,
       peg$c117 = peg$classExpectation(["\\", "'", "\"", "a", "b", "f", "n", "r", "t", "v"], false, false),
       peg$c118 = function(l) { 
             if(typeof l.join == "function") return l.join("");
             else return l;
           },
       peg$c119 = function(com) { return com.join(""); },
       peg$c120 = peg$anyExpectation(),
       peg$c121 = function(c) { return c; },
       peg$c122 = "-",
       peg$c123 = peg$literalExpectation("-", false),
       peg$c124 = "0o",
       peg$c125 = peg$literalExpectation("0o", false),
       peg$c126 = "0x",
       peg$c127 = peg$literalExpectation("0x", false),
       peg$c128 = /^[0-9]/,
       peg$c129 = peg$classExpectation([["0", "9"]], false, false),
       peg$c130 = function(l) {
             function j(arr) {
                 if(arr === null) return "";
                 if(!arr.join) return arr||"";
                 else return arr.map(x=>j(x)).join("");
             }
             return {
                 type: "NumericLiteral",
                 value: j(l)
             }
         },
       peg$c131 = "\r\n",
       peg$c132 = peg$literalExpectation("\r\n", false),
       peg$c133 = "\n",
       peg$c134 = peg$literalExpectation("\n", false),
       peg$c135 = "\r",
       peg$c136 = peg$literalExpectation("\r", false),
       peg$c137 = function(com) { if(com) return com; else return "\n"; },
       peg$c138 = "#",
       peg$c139 = peg$literalExpectation("#", false),
       peg$c140 = /^[^\n]/,
       peg$c141 = peg$classExpectation(["\n"], true, false),
       peg$c142 = /^[ ]/,
       peg$c143 = peg$classExpectation([" "], false, false),
       peg$c144 = "await",
       peg$c145 = peg$literalExpectation("await", false),
       peg$c146 = "@",
       peg$c147 = peg$literalExpectation("@", false),
       peg$c148 = function() { return '@' },
       peg$c149 = "async",
       peg$c150 = peg$literalExpectation("async", false),
       peg$c151 = function() { return "async" },
       peg$c152 = "def",
       peg$c153 = peg$literalExpectation("def", false),
       peg$c154 = function() { return "def" },
       peg$c155 = "->",
       peg$c156 = peg$literalExpectation("->", false),
       peg$c157 = function() { return "->" },
       peg$c158 = ":",
       peg$c159 = peg$literalExpectation(":", false),
       peg$c160 = function() { return ":" },
       peg$c161 = "(",
       peg$c162 = peg$literalExpectation("(", false),
       peg$c163 = function() { return "(" },
       peg$c164 = ")",
       peg$c165 = peg$literalExpectation(")", false),
       peg$c166 = function() { return ")" },
       peg$c167 = "=",
       peg$c168 = peg$literalExpectation("=", false),
       peg$c169 = function() { return "=" },
       peg$c170 = ",",
       peg$c171 = peg$literalExpectation(",", false),
       peg$c172 = function() { return "," },
       peg$c173 = "/",
       peg$c174 = peg$literalExpectation("/", false),
       peg$c175 = function() { return "/" },
       peg$c176 = "*",
       peg$c177 = peg$literalExpectation("*", false),
       peg$c178 = function() { return "*" },
       peg$c179 = "**",
       peg$c180 = peg$literalExpectation("**", false),
       peg$c181 = function() { return "**" },
       peg$c182 = ";",
       peg$c183 = peg$literalExpectation(";", false),
       peg$c184 = function() { return ";" },
       peg$c185 = "+=",
       peg$c186 = peg$literalExpectation("+=", false),
       peg$c187 = function() { return "+=" },
       peg$c188 = "-=",
       peg$c189 = peg$literalExpectation("-=", false),
       peg$c190 = function() { return "-=" },
       peg$c191 = "*=",
       peg$c192 = peg$literalExpectation("*=", false),
       peg$c193 = function() { return "*=" },
       peg$c194 = "@=",
       peg$c195 = peg$literalExpectation("@=", false),
       peg$c196 = function() { return "@=" },
       peg$c197 = "/=",
       peg$c198 = peg$literalExpectation("/=", false),
       peg$c199 = function() { return "/=" },
       peg$c200 = "%=",
       peg$c201 = peg$literalExpectation("%=", false),
       peg$c202 = function() { return "%=" },
       peg$c203 = "&=",
       peg$c204 = peg$literalExpectation("&=", false),
       peg$c205 = function() { return "&=" },
       peg$c206 = "|=",
       peg$c207 = peg$literalExpectation("|=", false),
       peg$c208 = function() { return "|=" },
       peg$c209 = "^=",
       peg$c210 = peg$literalExpectation("^=", false),
       peg$c211 = function() { return "^=" },
       peg$c212 = "<<=",
       peg$c213 = peg$literalExpectation("<<=", false),
       peg$c214 = function() { return "<<=" },
       peg$c215 = ">>=",
       peg$c216 = peg$literalExpectation(">>=", false),
       peg$c217 = function() { return ">>=" },
       peg$c218 = "**=",
       peg$c219 = peg$literalExpectation("**=", false),
       peg$c220 = function() { return "**=" },
       peg$c221 = "//=",
       peg$c222 = peg$literalExpectation("//=", false),
       peg$c223 = function() { return "//=" },
       peg$c224 = "exec",
       peg$c225 = peg$literalExpectation("exec", false),
       peg$c226 = function() { return "exec"; },
       peg$c227 = "del",
       peg$c228 = peg$literalExpectation("del", false),
       peg$c229 = function() { return "del" },
       peg$c230 = "pass",
       peg$c231 = peg$literalExpectation("pass", false),
       peg$c232 = function() { return "pass" },
       peg$c233 = "break",
       peg$c234 = peg$literalExpectation("break", false),
       peg$c235 = function() { return "break" },
       peg$c236 = "continue",
       peg$c237 = peg$literalExpectation("continue", false),
       peg$c238 = function() { return "continue" },
       peg$c239 = "return",
       peg$c240 = peg$literalExpectation("return", false),
       peg$c241 = function() { return "return" },
       peg$c242 = "raise",
       peg$c243 = peg$literalExpectation("raise", false),
       peg$c244 = function() { return "raise" },
       peg$c245 = "from",
       peg$c246 = peg$literalExpectation("from", false),
       peg$c247 = function() { return "from" },
       peg$c248 = "import",
       peg$c249 = peg$literalExpectation("import", false),
       peg$c250 = function() { return "import" },
       peg$c251 = ".",
       peg$c252 = peg$literalExpectation(".", false),
       peg$c253 = function() { return "." },
       peg$c254 = "..",
       peg$c255 = peg$literalExpectation("..", false),
       peg$c256 = function() { return ".." },
       peg$c257 = "...",
       peg$c258 = peg$literalExpectation("...", false),
       peg$c259 = function() { return "..." },
       peg$c260 = "as",
       peg$c261 = peg$literalExpectation("as", false),
       peg$c262 = function() { return "as" },
       peg$c263 = "global",
       peg$c264 = peg$literalExpectation("global", false),
       peg$c265 = function() { return "global" },
       peg$c266 = "nonlocal",
       peg$c267 = peg$literalExpectation("nonlocal", false),
       peg$c268 = function() { return "nonlocal" },
       peg$c269 = "assert",
       peg$c270 = peg$literalExpectation("assert", false),
       peg$c271 = function() { return "assert" },
       peg$c272 = "if",
       peg$c273 = peg$literalExpectation("if", false),
       peg$c274 = function() { return "if" },
       peg$c275 = "elif",
       peg$c276 = peg$literalExpectation("elif", false),
       peg$c277 = function() { return "elif" },
       peg$c278 = "else",
       peg$c279 = peg$literalExpectation("else", false),
       peg$c280 = function() { return "else" },
       peg$c281 = "while",
       peg$c282 = peg$literalExpectation("while", false),
       peg$c283 = function() { return "while" },
       peg$c284 = "print",
       peg$c285 = peg$literalExpectation("print", false),
       peg$c286 = function() { return "print" },
       peg$c287 = "for",
       peg$c288 = peg$literalExpectation("for", false),
       peg$c289 = function() { return "for" },
       peg$c290 = "in",
       peg$c291 = peg$literalExpectation("in", false),
       peg$c292 = function() { return "in" },
       peg$c293 = "try",
       peg$c294 = peg$literalExpectation("try", false),
       peg$c295 = function() { return "try" },
       peg$c296 = "finally",
       peg$c297 = peg$literalExpectation("finally", false),
       peg$c298 = function() { return "finally" },
       peg$c299 = "with",
       peg$c300 = peg$literalExpectation("with", false),
       peg$c301 = function() { return "with" },
       peg$c302 = "except",
       peg$c303 = peg$literalExpectation("except", false),
       peg$c304 = function() { return "except" },
       peg$c305 = ":=",
       peg$c306 = peg$literalExpectation(":=", false),
       peg$c307 = function() { return ":=" },
       peg$c308 = "lambda",
       peg$c309 = peg$literalExpectation("lambda", false),
       peg$c310 = function() { return "lambda" },
       peg$c311 = "or",
       peg$c312 = peg$literalExpectation("or", false),
       peg$c313 = function() { return "or" },
       peg$c314 = "and",
       peg$c315 = peg$literalExpectation("and", false),
       peg$c316 = function() { return "and" },
       peg$c317 = "not",
       peg$c318 = peg$literalExpectation("not", false),
       peg$c319 = function() { return "not" },
       peg$c320 = "<",
       peg$c321 = peg$literalExpectation("<", false),
       peg$c322 = function() { return "<" },
       peg$c323 = ">",
       peg$c324 = peg$literalExpectation(">", false),
       peg$c325 = function() { return ">" },
       peg$c326 = "==",
       peg$c327 = peg$literalExpectation("==", false),
       peg$c328 = function() { return "==" },
       peg$c329 = ">=",
       peg$c330 = peg$literalExpectation(">=", false),
       peg$c331 = function() { return ">=" },
       peg$c332 = "<=",
       peg$c333 = peg$literalExpectation("<=", false),
       peg$c334 = function() { return "<=" },
       peg$c335 = "<>",
       peg$c336 = peg$literalExpectation("<>", false),
       peg$c337 = function() { return "<>" },
       peg$c338 = "!=",
       peg$c339 = peg$literalExpectation("!=", false),
       peg$c340 = function() { return "!=" },
       peg$c341 = "is",
       peg$c342 = peg$literalExpectation("is", false),
       peg$c343 = function() { return "is" },
       peg$c344 = "|",
       peg$c345 = peg$literalExpectation("|", false),
       peg$c346 = function() { return "|" },
       peg$c347 = "^",
       peg$c348 = peg$literalExpectation("^", false),
       peg$c349 = function() { return "^" },
       peg$c350 = "&",
       peg$c351 = peg$literalExpectation("&", false),
       peg$c352 = function() { return "&" },
       peg$c353 = "<<",
       peg$c354 = peg$literalExpectation("<<", false),
       peg$c355 = function() { return "<<" },
       peg$c356 = ">>",
       peg$c357 = peg$literalExpectation(">>", false),
       peg$c358 = function() { return ">>" },
       peg$c359 = "+",
       peg$c360 = peg$literalExpectation("+", false),
       peg$c361 = function() { return "+" },
       peg$c362 = function() { return "-" },
       peg$c363 = "%",
       peg$c364 = peg$literalExpectation("%", false),
       peg$c365 = function() { return "%" },
       peg$c366 = "//",
       peg$c367 = peg$literalExpectation("//", false),
       peg$c368 = function() { return "//" },
       peg$c369 = "~",
       peg$c370 = peg$literalExpectation("~", false),
       peg$c371 = function() { return "~" },
       peg$c372 = "[",
       peg$c373 = peg$literalExpectation("[", false),
       peg$c374 = function() { return "[" },
       peg$c375 = "]",
       peg$c376 = peg$literalExpectation("]", false),
       peg$c377 = function() { return "]" },
       peg$c378 = "{",
       peg$c379 = peg$literalExpectation("{", false),
       peg$c380 = function() { return "{}".substring(0,1); },
       peg$c381 = "}",
       peg$c382 = peg$literalExpectation("}", false),
       peg$c383 = function() { return "{}".substring(1,2); },
       peg$c384 = "None",
       peg$c385 = peg$literalExpectation("None", false),
       peg$c386 = function() { return "None" },
       peg$c387 = "True",
       peg$c388 = peg$literalExpectation("True", false),
       peg$c389 = function() { return {type: "BooleanLiteral", value: "True"}; },
       peg$c390 = "False",
       peg$c391 = peg$literalExpectation("False", false),
       peg$c392 = function() { return {type: "BooleanLiteral", value: "False"}; },
       peg$c393 = "class",
       peg$c394 = peg$literalExpectation("class", false),
       peg$c395 = function() { return "class" },
       peg$c396 = "yield",
       peg$c397 = peg$literalExpectation("yield", false),
       peg$c398 = function() { return "yield" },
       peg$c399 = "\"",
       peg$c400 = peg$literalExpectation("\"", false),
       peg$c401 = function() { return '"'; },
       peg$c402 = "'",
       peg$c403 = peg$literalExpectation("'", false),
       peg$c404 = function() { return "'"; },
       peg$c405 = /^[a-zA-Z_]/,
       peg$c406 = peg$classExpectation([["a", "z"], ["A", "Z"], "_"], false, false),
       peg$c407 = /^[a-zA-Z0-9_]/,
       peg$c408 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "_"], false, false),
       peg$c409 = function(head, tail) {
           var name = head + tail.join("");
           var reserved = ["and","as","assert","break","class","continue","def","del","elif",
                           "else","except","finally","for","from","global","if","import","in","is",
                           "lambda","None","nonlocal","not","or","pass","raise","return","try","while",
                           "with","yield","False","True"];
           if(reserved.indexOf(name) == -1) return true;
           else return false;
           },
       peg$c410 = function(head, tail) { return {
               type: "Identifier",
               value: head + tail.join("")
             }; 
           },

       peg$currPos          = 0,
       peg$savedPos         = 0,
       peg$posDetailsCache  = [{ line: 1, column: 1 }],
       peg$maxFailPos       = 0,
       peg$maxFailExpected  = [],
       peg$silentFails      = 0,

       peg$result;

   if ("startRule" in options) {
     if (!(options.startRule in peg$startRuleFunctions)) {
       throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
     }

     peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
   }

   function text() {
     return input.substring(peg$savedPos, peg$currPos);
   }

   function location() {
     return peg$computeLocation(peg$savedPos, peg$currPos);
   }

   function expected(description, location) {
     location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

     throw peg$buildStructuredError(
       [peg$otherExpectation(description)],
       input.substring(peg$savedPos, peg$currPos),
       location
     );
   }

   function error(message, location) {
     location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

     throw peg$buildSimpleError(message, location);
   }

   function peg$literalExpectation(text, ignoreCase) {
     return { type: "literal", text: text, ignoreCase: ignoreCase };
   }

   function peg$classExpectation(parts, inverted, ignoreCase) {
     return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
   }

   function peg$anyExpectation() {
     return { type: "any" };
   }

   function peg$endExpectation() {
     return { type: "end" };
   }

   function peg$otherExpectation(description) {
     return { type: "other", description: description };
   }

   function peg$computePosDetails(pos) {
     var details = peg$posDetailsCache[pos], p;

     if (details) {
       return details;
     } else {
       p = pos - 1;
       while (!peg$posDetailsCache[p]) {
         p--;
       }

       details = peg$posDetailsCache[p];
       details = {
         line:   details.line,
         column: details.column
       };

       while (p < pos) {
         if (input.charCodeAt(p) === 10) {
           details.line++;
           details.column = 1;
         } else {
           details.column++;
         }

         p++;
       }

       peg$posDetailsCache[pos] = details;
       return details;
     }
   }

   function peg$computeLocation(startPos, endPos) {
     var startPosDetails = peg$computePosDetails(startPos),
         endPosDetails   = peg$computePosDetails(endPos);

     return {
       start: {
         offset: startPos,
         line:   startPosDetails.line,
         column: startPosDetails.column
       },
       end: {
         offset: endPos,
         line:   endPosDetails.line,
         column: endPosDetails.column
       }
     };
   }

   function peg$fail(expected) {
     if (peg$currPos < peg$maxFailPos) { return; }

     if (peg$currPos > peg$maxFailPos) {
       peg$maxFailPos = peg$currPos;
       peg$maxFailExpected = [];
     }

     peg$maxFailExpected.push(expected);
   }

   function peg$buildSimpleError(message, location) {
     return new peg$SyntaxError(message, null, null, location);
   }

   function peg$buildStructuredError(expected, found, location) {
     return new peg$SyntaxError(
       peg$SyntaxError.buildMessage(expected, found),
       expected,
       found,
       location
     );
   }

   function peg$parsefile_input() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = [];
     s2 = peg$parseNEWLINE();
     if (s2 === peg$FAILED) {
       s2 = peg$parsestmt();
       if (s2 === peg$FAILED) {
         s2 = peg$parsecomment();
       }
     }
     while (s2 !== peg$FAILED) {
       s1.push(s2);
       s2 = peg$parseNEWLINE();
       if (s2 === peg$FAILED) {
         s2 = peg$parsestmt();
         if (s2 === peg$FAILED) {
           s2 = peg$parsecomment();
         }
       }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parseEOF();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c0(s1);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseeval_input() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parsetestlist();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$parseNEWLINE();
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$parseNEWLINE();
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseEOF();
         if (s3 !== peg$FAILED) {
           s1 = [s1, s2, s3];
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsedecorator() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parseAT();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsenamedexpr_test();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseNEWLINE();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c1(s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsedecorators() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = [];
     s2 = peg$parsedecorator();
     if (s2 !== peg$FAILED) {
       while (s2 !== peg$FAILED) {
         s1.push(s2);
         s2 = peg$parsedecorator();
       }
     } else {
       s1 = peg$FAILED;
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c2(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsedecorated() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parsedecorators();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsefuncdef();
       if (s2 === peg$FAILED) {
         s2 = peg$parseasync_funcdef();
         if (s2 === peg$FAILED) {
           s2 = peg$parseclassdef();
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c3(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseasync_funcdef() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseASYNC();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsefuncdef();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c4(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsefuncdef() {
     var s0, s1, s2, s3, s4, s5, s6, s7;

     s0 = peg$currPos;
     s1 = peg$parseDEF();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseNAME();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseparameters();
         if (s3 !== peg$FAILED) {
           s4 = peg$currPos;
           s5 = peg$parseLAMBDA_ARROW();
           if (s5 !== peg$FAILED) {
             s6 = peg$parsetest();
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             s5 = peg$parseCOLON();
             if (s5 !== peg$FAILED) {
               s6 = peg$parseCOMMENT();
               if (s6 === peg$FAILED) {
                 s6 = null;
               }
               if (s6 !== peg$FAILED) {
                 s7 = peg$parsesuite();
                 if (s7 !== peg$FAILED) {
                   peg$savedPos = s0;
                   s1 = peg$c5(s2, s3, s6, s7);
                   s0 = s1;
                 } else {
                   peg$currPos = s0;
                   s0 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s0;
                 s0 = peg$FAILED;
               }
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseparameters() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parseOPEN_PAREN();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetypedargslist();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCLOSE_PAREN();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c6(s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsearguments() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseargument();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseCOMMENT();
         if (s5 === peg$FAILED) {
           s5 = null;
         }
         if (s5 !== peg$FAILED) {
           s6 = peg$parseargument();
           if (s6 !== peg$FAILED) {
             s4 = [s4, s5, s6];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseCOMMENT();
           if (s5 === peg$FAILED) {
             s5 = null;
           }
           if (s5 !== peg$FAILED) {
             s6 = peg$parseargument();
             if (s6 !== peg$FAILED) {
               s4 = [s4, s5, s6];
               s3 = s4;
             } else {
               peg$currPos = s3;
               s3 = peg$FAILED;
             }
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c7(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsekwargs() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseDOUBLESTAR();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetfpdef();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCOMMENT();
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c8(s2);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseargs() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseSTAR();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetfpdef();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c9(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsekwonly_kwargs() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = [];
     s2 = peg$currPos;
     s3 = peg$parseCOMMA();
     if (s3 !== peg$FAILED) {
       s4 = peg$parseCOMMENT();
       if (s4 === peg$FAILED) {
         s4 = null;
       }
       if (s4 !== peg$FAILED) {
         s5 = peg$parseargument();
         if (s5 !== peg$FAILED) {
           s3 = [s3, s4, s5];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
     } else {
       peg$currPos = s2;
       s2 = peg$FAILED;
     }
     while (s2 !== peg$FAILED) {
       s1.push(s2);
       s2 = peg$currPos;
       s3 = peg$parseCOMMA();
       if (s3 !== peg$FAILED) {
         s4 = peg$parseCOMMENT();
         if (s4 === peg$FAILED) {
           s4 = null;
         }
         if (s4 !== peg$FAILED) {
           s5 = peg$parseargument();
           if (s5 !== peg$FAILED) {
             s3 = [s3, s4, s5];
             s2 = s3;
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parseCOMMENT();
       if (s2 === peg$FAILED) {
         s2 = peg$currPos;
         s3 = peg$parseCOMMA();
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCOMMENT();
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             s5 = peg$parsekwargs();
             if (s5 === peg$FAILED) {
               s5 = null;
             }
             if (s5 !== peg$FAILED) {
               s3 = [s3, s4, s5];
               s2 = s3;
             } else {
               peg$currPos = s2;
               s2 = peg$FAILED;
             }
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
         if (s2 === peg$FAILED) {
           s2 = null;
         }
       }
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseargs_kwonly_kwargs() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseargs();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsekwonly_kwargs();
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$parsekwargs();
     }

     return s0;
   }

   function peg$parseposkeyword_args_kwonly_kwargs() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsearguments();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseCOMMENT();
       if (s2 === peg$FAILED) {
         s2 = peg$currPos;
         s3 = peg$parseCOMMA();
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCOMMENT();
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             s5 = peg$parseargs_kwonly_kwargs();
             if (s5 === peg$FAILED) {
               s5 = null;
             }
             if (s5 !== peg$FAILED) {
               s3 = [s3, s4, s5];
               s2 = s3;
             } else {
               peg$currPos = s2;
               s2 = peg$FAILED;
             }
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
         if (s2 === peg$FAILED) {
           s2 = null;
         }
       }
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetypedargslist_no_posonly() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseposkeyword_args_kwonly_kwargs();
     if (s1 === peg$FAILED) {
       s1 = peg$parseargs_kwonly_kwargs();
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c10(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsetypedarglist() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

     s0 = peg$currPos;
     s1 = peg$parsearguments();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseCOMMA();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMENT();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseSLASH();
           if (s4 !== peg$FAILED) {
             s5 = peg$currPos;
             s6 = peg$parseCOMMA();
             if (s6 !== peg$FAILED) {
               s7 = peg$currPos;
               s8 = peg$parseCOMMENT();
               if (s8 === peg$FAILED) {
                 s8 = null;
               }
               if (s8 !== peg$FAILED) {
                 s9 = peg$parsetypedargslist_no_posonly();
                 if (s9 !== peg$FAILED) {
                   s8 = [s8, s9];
                   s7 = s8;
                 } else {
                   peg$currPos = s7;
                   s7 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s7;
                 s7 = peg$FAILED;
               }
               if (s7 === peg$FAILED) {
                 s7 = null;
               }
               if (s7 !== peg$FAILED) {
                 s6 = [s6, s7];
                 s5 = s6;
               } else {
                 peg$currPos = s5;
                 s5 = peg$FAILED;
               }
             } else {
               peg$currPos = s5;
               s5 = peg$FAILED;
             }
             if (s5 === peg$FAILED) {
               s5 = null;
             }
             if (s5 !== peg$FAILED) {
               s1 = [s1, s2, s3, s4, s5];
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$parsetypedargslist_no_posonly();
     }

     return s0;
   }

   function peg$parsetypedargslist() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsetypedarglist();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c11(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsetfpdef() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseNAME();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseCOLON();
       if (s3 !== peg$FAILED) {
         s4 = peg$parsetest();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c12(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsevararglist_no_posonly() {
     var s0;

     s0 = peg$parseposkeyword_args_kwonly_kwargs();
     if (s0 === peg$FAILED) {
       s0 = peg$parseargs_kwonly_kwargs();
     }

     return s0;
   }

   function peg$parsevarargslist() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parsearguments();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseCOMMA();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseSLASH();
         if (s3 !== peg$FAILED) {
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parsevararglist_no_posonly();
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c13(s1, s4);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parsevararglist_no_posonly();
       if (s1 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c14(s1);
       }
       s0 = s1;
     }

     return s0;
   }

   function peg$parsestmt() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parsesimple_stmt();
     if (s1 === peg$FAILED) {
       s1 = peg$parsecompound_stmt();
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parsecomment();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c15(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsesimple_stmt() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsesmall_stmt();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseSEMICOLON();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsesmall_stmt();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseSEMICOLON();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsesmall_stmt();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseSEMICOLON();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c16(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsesmall_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsedel_stmt();
     if (s1 === peg$FAILED) {
       s1 = peg$parsepass_stmt();
       if (s1 === peg$FAILED) {
         s1 = peg$parseflow_stmt();
         if (s1 === peg$FAILED) {
           s1 = peg$parseimport_stmt();
           if (s1 === peg$FAILED) {
             s1 = peg$parseglobal_stmt();
             if (s1 === peg$FAILED) {
               s1 = peg$parsenonlocal_stmt();
               if (s1 === peg$FAILED) {
                 s1 = peg$parseassert_stmt();
                 if (s1 === peg$FAILED) {
                   s1 = peg$parseexec_stmt();
                   if (s1 === peg$FAILED) {
                     s1 = peg$parseprint_stmt();
                     if (s1 === peg$FAILED) {
                       s1 = peg$parseexpr_stmt();
                     }
                   }
                 }
               }
             }
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c17(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseexpr_stmt() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parsetestlist_star_expr();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseannassign();
       if (s2 === peg$FAILED) {
         s2 = peg$currPos;
         s3 = peg$parseaugassign();
         if (s3 !== peg$FAILED) {
           s4 = peg$parseyield_expr();
           if (s4 === peg$FAILED) {
             s4 = peg$parsetestlist();
           }
           if (s4 !== peg$FAILED) {
             s3 = [s3, s4];
             s2 = s3;
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
         if (s2 === peg$FAILED) {
           s2 = peg$currPos;
           s3 = [];
           s4 = peg$currPos;
           s5 = peg$parseEQUALS();
           if (s5 !== peg$FAILED) {
             s6 = peg$parseyield_expr();
             if (s6 === peg$FAILED) {
               s6 = peg$parsetestlist_star_expr();
             }
             if (s6 !== peg$FAILED) {
               peg$savedPos = s4;
               s5 = peg$c18(s1, s6);
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           if (s4 !== peg$FAILED) {
             while (s4 !== peg$FAILED) {
               s3.push(s4);
               s4 = peg$currPos;
               s5 = peg$parseEQUALS();
               if (s5 !== peg$FAILED) {
                 s6 = peg$parseyield_expr();
                 if (s6 === peg$FAILED) {
                   s6 = peg$parsetestlist_star_expr();
                 }
                 if (s6 !== peg$FAILED) {
                   peg$savedPos = s4;
                   s5 = peg$c18(s1, s6);
                   s4 = s5;
                 } else {
                   peg$currPos = s4;
                   s4 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             }
           } else {
             s3 = peg$FAILED;
           }
           if (s3 !== peg$FAILED) {
             s4 = peg$parseCOMMENT();
             if (s4 === peg$FAILED) {
               s4 = null;
             }
             if (s4 !== peg$FAILED) {
               peg$savedPos = s2;
               s3 = peg$c19(s1, s3, s4);
               s2 = s3;
             } else {
               peg$currPos = s2;
               s2 = peg$FAILED;
             }
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
           if (s2 === peg$FAILED) {
             s2 = null;
           }
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c20(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseannassign() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseCOLON();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetest();
       if (s2 !== peg$FAILED) {
         s3 = peg$currPos;
         s4 = peg$parseEQUALS();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseyield_expr();
           if (s5 === peg$FAILED) {
             s5 = peg$parsetestlist_star_expr();
           }
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s1 = [s1, s2, s3];
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetestlist_star_expr() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsetest();
     if (s1 === peg$FAILED) {
       s1 = peg$parsestar_expr();
     }
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsetest();
         if (s5 === peg$FAILED) {
           s5 = peg$parsestar_expr();
         }
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsetest();
           if (s5 === peg$FAILED) {
             s5 = peg$parsestar_expr();
           }
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c21(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseaugassign() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsePLUSEQUAL();
     if (s1 === peg$FAILED) {
       s1 = peg$parseMINEQUAL();
       if (s1 === peg$FAILED) {
         s1 = peg$parseSTAREQUAL();
         if (s1 === peg$FAILED) {
           s1 = peg$parseATEQUAL();
           if (s1 === peg$FAILED) {
             s1 = peg$parseSLASHEQUAL();
             if (s1 === peg$FAILED) {
               s1 = peg$parsePERCENTEQUAL();
               if (s1 === peg$FAILED) {
                 s1 = peg$parseAMPEREQUAL();
                 if (s1 === peg$FAILED) {
                   s1 = peg$parseVBAREQUAL();
                   if (s1 === peg$FAILED) {
                     s1 = peg$parseCIRCUMFLEXEQUAL();
                     if (s1 === peg$FAILED) {
                       s1 = peg$parseLEFTSHIFTEQUAL();
                       if (s1 === peg$FAILED) {
                         s1 = peg$parseRIGHTSHIFTEQUAL();
                         if (s1 === peg$FAILED) {
                           s1 = peg$parseDOUBLESTAREQUAL();
                           if (s1 === peg$FAILED) {
                             s1 = peg$parseDOUBLESLASHEQUAL();
                           }
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c22(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsedel_stmt() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseDEL();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseexprlist();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c23(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsepass_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsePASS();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c24();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseflow_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsebreak_stmt();
     if (s1 === peg$FAILED) {
       s1 = peg$parsecontinue_stmt();
       if (s1 === peg$FAILED) {
         s1 = peg$parsereturn_stmt();
         if (s1 === peg$FAILED) {
           s1 = peg$parseraise_stmt();
           if (s1 === peg$FAILED) {
             s1 = peg$parseyield_stmt();
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c17(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsebreak_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseBREAK();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c25();
     }
     s0 = s1;

     return s0;
   }

   function peg$parsecontinue_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseCONTINUE();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c26();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseexec_stmt() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseEXEC();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseSTRING();
       if (s2 !== peg$FAILED) {
         s3 = peg$currPos;
         s4 = peg$parseIN();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsetestlist_star_expr();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c27(s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsereturn_stmt() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseRETURN();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetestlist_star_expr();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c28(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseprint_stmt() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parsePRINT();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetestlist_star_expr();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c29(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseyield_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseyield_expr();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c30(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseraise_stmt() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseRAISE();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parsetest();
       if (s3 !== peg$FAILED) {
         s4 = peg$currPos;
         s5 = peg$parseFROM();
         if (s5 !== peg$FAILED) {
           s6 = peg$parsetest();
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         if (s4 === peg$FAILED) {
           s4 = null;
         }
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c31(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseimport_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseimport_name();
     if (s1 === peg$FAILED) {
       s1 = peg$parseimport_from();
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c11(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseimport_name() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseIMPORT();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsedotted_as_names();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c32(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseimport_from() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8;

     s0 = peg$currPos;
     s1 = peg$parseFROM();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$parseELLIPSIS();
       if (s3 === peg$FAILED) {
         s3 = peg$parseDOT();
         if (s3 === peg$FAILED) {
           s3 = peg$parseDOUBLEDOT();
         }
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$parseELLIPSIS();
         if (s3 === peg$FAILED) {
           s3 = peg$parseDOT();
           if (s3 === peg$FAILED) {
             s3 = peg$parseDOUBLEDOT();
           }
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parsedotted_name();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseIMPORT();
           if (s4 !== peg$FAILED) {
             s5 = peg$parseimport_as_names();
             if (s5 === peg$FAILED) {
               s5 = peg$parseSTAR();
               if (s5 === peg$FAILED) {
                 s5 = peg$currPos;
                 s6 = peg$parseOPEN_PAREN();
                 if (s6 !== peg$FAILED) {
                   s7 = peg$parseimport_as_names();
                   if (s7 !== peg$FAILED) {
                     s8 = peg$parseCLOSE_PAREN();
                     if (s8 !== peg$FAILED) {
                       s6 = [s6, s7, s8];
                       s5 = s6;
                     } else {
                       peg$currPos = s5;
                       s5 = peg$FAILED;
                     }
                   } else {
                     peg$currPos = s5;
                     s5 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s5;
                   s5 = peg$FAILED;
                 }
               }
             }
             if (s5 !== peg$FAILED) {
               peg$savedPos = s0;
               s1 = peg$c33(s2, s3, s5);
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseimport_as_name() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseNAME();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseAS();
       if (s3 !== peg$FAILED) {
         s4 = peg$parseNAME();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c34(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsedotted_as_name() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parsedotted_name();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseAS();
       if (s3 !== peg$FAILED) {
         s4 = peg$parseNAME();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c35(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseimport_as_names() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseimport_as_name();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseimport_as_name();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseimport_as_name();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c36(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsedotted_as_names() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsedotted_as_name();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsedotted_as_name();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsedotted_as_name();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c36(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsedotted_name() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseNAME();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseDOT();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseNAME();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseDOT();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseNAME();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c37(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseglobal_stmt() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseGLOBAL();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseNAME();
       if (s2 !== peg$FAILED) {
         s3 = [];
         s4 = peg$currPos;
         s5 = peg$parseCOMMA();
         if (s5 !== peg$FAILED) {
           s6 = peg$parseNAME();
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parseNAME();
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c38(s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsenonlocal_stmt() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseNONLOCAL();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseNAME();
       if (s2 !== peg$FAILED) {
         s3 = [];
         s4 = peg$currPos;
         s5 = peg$parseCOMMA();
         if (s5 !== peg$FAILED) {
           s6 = peg$parseNAME();
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parseNAME();
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c39(s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseassert_stmt() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseASSERT();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetest();
       if (s2 !== peg$FAILED) {
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsetest();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c40(s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsecompound_stmt() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseif_stmt();
     if (s1 === peg$FAILED) {
       s1 = peg$parsewhile_stmt();
       if (s1 === peg$FAILED) {
         s1 = peg$parsefor_stmt();
         if (s1 === peg$FAILED) {
           s1 = peg$parsetry_stmt();
           if (s1 === peg$FAILED) {
             s1 = peg$parsewith_stmt();
             if (s1 === peg$FAILED) {
               s1 = peg$parsefuncdef();
               if (s1 === peg$FAILED) {
                 s1 = peg$parseclassdef();
                 if (s1 === peg$FAILED) {
                   s1 = peg$parsedecorated();
                   if (s1 === peg$FAILED) {
                     s1 = peg$parseasync_stmt();
                   }
                 }
               }
             }
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c17(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseasync_stmt() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseASYNC();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsefuncdef();
       if (s2 === peg$FAILED) {
         s2 = peg$parsewith_stmt();
         if (s2 === peg$FAILED) {
           s2 = peg$parsefor_stmt();
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c41(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseif_stmt() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

     s0 = peg$currPos;
     s1 = peg$parseIF();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsenamedexpr_test();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOLON();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsesuite();
           if (s4 !== peg$FAILED) {
             s5 = [];
             s6 = peg$parseelif_block();
             while (s6 !== peg$FAILED) {
               s5.push(s6);
               s6 = peg$parseelif_block();
             }
             if (s5 !== peg$FAILED) {
               s6 = peg$currPos;
               s7 = peg$parseELSE();
               if (s7 !== peg$FAILED) {
                 s8 = peg$parseCOLON();
                 if (s8 !== peg$FAILED) {
                   s9 = peg$parsesuite();
                   if (s9 !== peg$FAILED) {
                     s7 = [s7, s8, s9];
                     s6 = s7;
                   } else {
                     peg$currPos = s6;
                     s6 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s6;
                   s6 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
               if (s6 === peg$FAILED) {
                 s6 = null;
               }
               if (s6 !== peg$FAILED) {
                 peg$savedPos = s0;
                 s1 = peg$c42(s2, s4, s5, s6);
                 s0 = s1;
               } else {
                 peg$currPos = s0;
                 s0 = peg$FAILED;
               }
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseelif_block() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseELIF();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsenamedexpr_test();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOLON();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsesuite();
           if (s4 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c43(s2, s4);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsewhile_stmt() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8;

     s0 = peg$currPos;
     s1 = peg$parseWHILE();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsenamedexpr_test();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOLON();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsesuite();
           if (s4 !== peg$FAILED) {
             s5 = peg$currPos;
             s6 = peg$parseELSE();
             if (s6 !== peg$FAILED) {
               s7 = peg$parseCOLON();
               if (s7 !== peg$FAILED) {
                 s8 = peg$parsesuite();
                 if (s8 !== peg$FAILED) {
                   s6 = [s6, s7, s8];
                   s5 = s6;
                 } else {
                   peg$currPos = s5;
                   s5 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s5;
                 s5 = peg$FAILED;
               }
             } else {
               peg$currPos = s5;
               s5 = peg$FAILED;
             }
             if (s5 === peg$FAILED) {
               s5 = null;
             }
             if (s5 !== peg$FAILED) {
               peg$savedPos = s0;
               s1 = peg$c44(s2, s4, s5);
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsefor_stmt() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

     s0 = peg$currPos;
     s1 = peg$parseFOR();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseexprlist();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseIN();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsetestlist();
           if (s4 !== peg$FAILED) {
             s5 = peg$parseCOLON();
             if (s5 !== peg$FAILED) {
               s6 = peg$parseCOMMENT();
               if (s6 === peg$FAILED) {
                 s6 = null;
               }
               if (s6 !== peg$FAILED) {
                 s7 = peg$parsesuite();
                 if (s7 !== peg$FAILED) {
                   s8 = peg$currPos;
                   s9 = peg$parseELSE();
                   if (s9 !== peg$FAILED) {
                     s10 = peg$parseCOLON();
                     if (s10 !== peg$FAILED) {
                       s11 = peg$parsesuite();
                       if (s11 !== peg$FAILED) {
                         s9 = [s9, s10, s11];
                         s8 = s9;
                       } else {
                         peg$currPos = s8;
                         s8 = peg$FAILED;
                       }
                     } else {
                       peg$currPos = s8;
                       s8 = peg$FAILED;
                     }
                   } else {
                     peg$currPos = s8;
                     s8 = peg$FAILED;
                   }
                   if (s8 === peg$FAILED) {
                     s8 = null;
                   }
                   if (s8 !== peg$FAILED) {
                     peg$savedPos = s0;
                     s1 = peg$c45(s2, s4, s7, s8);
                     s0 = s1;
                   } else {
                     peg$currPos = s0;
                     s0 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s0;
                   s0 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s0;
                 s0 = peg$FAILED;
               }
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetry_stmt() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

     s0 = peg$currPos;
     s1 = peg$parseTRY();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseCOLON();
       if (s2 !== peg$FAILED) {
         s3 = peg$parsesuite();
         if (s3 !== peg$FAILED) {
           s4 = peg$currPos;
           s5 = [];
           s6 = peg$currPos;
           s7 = peg$parseexcept_clause();
           if (s7 !== peg$FAILED) {
             s8 = peg$parseCOLON();
             if (s8 !== peg$FAILED) {
               s9 = peg$parsesuite();
               if (s9 !== peg$FAILED) {
                 s7 = [s7, s8, s9];
                 s6 = s7;
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             } else {
               peg$currPos = s6;
               s6 = peg$FAILED;
             }
           } else {
             peg$currPos = s6;
             s6 = peg$FAILED;
           }
           if (s6 !== peg$FAILED) {
             while (s6 !== peg$FAILED) {
               s5.push(s6);
               s6 = peg$currPos;
               s7 = peg$parseexcept_clause();
               if (s7 !== peg$FAILED) {
                 s8 = peg$parseCOLON();
                 if (s8 !== peg$FAILED) {
                   s9 = peg$parsesuite();
                   if (s9 !== peg$FAILED) {
                     s7 = [s7, s8, s9];
                     s6 = s7;
                   } else {
                     peg$currPos = s6;
                     s6 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s6;
                   s6 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             }
           } else {
             s5 = peg$FAILED;
           }
           if (s5 !== peg$FAILED) {
             s6 = peg$currPos;
             s7 = peg$parseELSE();
             if (s7 !== peg$FAILED) {
               s8 = peg$parseCOLON();
               if (s8 !== peg$FAILED) {
                 s9 = peg$parsesuite();
                 if (s9 !== peg$FAILED) {
                   s7 = [s7, s8, s9];
                   s6 = s7;
                 } else {
                   peg$currPos = s6;
                   s6 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             } else {
               peg$currPos = s6;
               s6 = peg$FAILED;
             }
             if (s6 === peg$FAILED) {
               s6 = null;
             }
             if (s6 !== peg$FAILED) {
               s7 = peg$currPos;
               s8 = peg$parseFINALLY();
               if (s8 !== peg$FAILED) {
                 s9 = peg$parseCOLON();
                 if (s9 !== peg$FAILED) {
                   s10 = peg$parsesuite();
                   if (s10 !== peg$FAILED) {
                     s8 = [s8, s9, s10];
                     s7 = s8;
                   } else {
                     peg$currPos = s7;
                     s7 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s7;
                   s7 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s7;
                 s7 = peg$FAILED;
               }
               if (s7 === peg$FAILED) {
                 s7 = null;
               }
               if (s7 !== peg$FAILED) {
                 peg$savedPos = s4;
                 s5 = peg$c46(s3, s5, s6, s7);
                 s4 = s5;
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           if (s4 === peg$FAILED) {
             s4 = peg$currPos;
             s5 = peg$parseFINALLY();
             if (s5 !== peg$FAILED) {
               s6 = peg$parseCOLON();
               if (s6 !== peg$FAILED) {
                 s7 = peg$parsesuite();
                 if (s7 !== peg$FAILED) {
                   peg$savedPos = s4;
                   s5 = peg$c47(s3, s7);
                   s4 = s5;
                 } else {
                   peg$currPos = s4;
                   s4 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           }
           if (s4 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c48(s3, s4);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsewith_stmt() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseWITH();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsewith_item_list();
       if (s2 === peg$FAILED) {
         s2 = peg$parsewith_item();
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOLON();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsesuite();
           if (s4 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c49(s2, s4);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsewith_item_list() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseOPEN_PAREN();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsewith_item();
       if (s2 !== peg$FAILED) {
         s3 = [];
         s4 = peg$currPos;
         s5 = peg$parseCOMMA();
         if (s5 !== peg$FAILED) {
           s6 = peg$parsewith_item();
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         if (s4 !== peg$FAILED) {
           while (s4 !== peg$FAILED) {
             s3.push(s4);
             s4 = peg$currPos;
             s5 = peg$parseCOMMA();
             if (s5 !== peg$FAILED) {
               s6 = peg$parsewith_item();
               if (s6 !== peg$FAILED) {
                 s5 = [s5, s6];
                 s4 = s5;
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           }
         } else {
           s3 = peg$FAILED;
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCLOSE_PAREN();
           if (s4 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c50(s2, s3);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsewith_item() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parsetest();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseAS();
       if (s3 !== peg$FAILED) {
         s4 = peg$parseexpr();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c51(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseexcept_clause() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseEXCEPT();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseexcept_clause_param();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s3 = [];
         s4 = peg$currPos;
         s5 = peg$parseCOMMA();
         if (s5 !== peg$FAILED) {
           s6 = peg$parseexcept_clause_param();
           if (s6 === peg$FAILED) {
             s6 = null;
           }
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parseexcept_clause_param();
             if (s6 === peg$FAILED) {
               s6 = null;
             }
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c52(s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseexcept_clause_param() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parsetest();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseAS();
       if (s3 !== peg$FAILED) {
         s4 = peg$parseNAME();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c53(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsesuite() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8;

     s0 = peg$parsesimple_stmt();
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parseNEWLINE();
       if (s1 !== peg$FAILED) {
         s2 = peg$parseINDENT();
         if (s2 !== peg$FAILED) {
           s3 = [];
           s4 = peg$currPos;
           s5 = peg$parseSAMEDENT();
           if (s5 !== peg$FAILED) {
             s6 = peg$parseNEWLINE();
             if (s6 === peg$FAILED) {
               s6 = peg$currPos;
               s7 = peg$parsestmt();
               if (s7 !== peg$FAILED) {
                 s8 = peg$parseNEWLINE();
                 if (s8 === peg$FAILED) {
                   s8 = null;
                 }
                 if (s8 !== peg$FAILED) {
                   s7 = [s7, s8];
                   s6 = s7;
                 } else {
                   peg$currPos = s6;
                   s6 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             }
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           if (s4 !== peg$FAILED) {
             while (s4 !== peg$FAILED) {
               s3.push(s4);
               s4 = peg$currPos;
               s5 = peg$parseSAMEDENT();
               if (s5 !== peg$FAILED) {
                 s6 = peg$parseNEWLINE();
                 if (s6 === peg$FAILED) {
                   s6 = peg$currPos;
                   s7 = peg$parsestmt();
                   if (s7 !== peg$FAILED) {
                     s8 = peg$parseNEWLINE();
                     if (s8 === peg$FAILED) {
                       s8 = null;
                     }
                     if (s8 !== peg$FAILED) {
                       s7 = [s7, s8];
                       s6 = s7;
                     } else {
                       peg$currPos = s6;
                       s6 = peg$FAILED;
                     }
                   } else {
                     peg$currPos = s6;
                     s6 = peg$FAILED;
                   }
                 }
                 if (s6 !== peg$FAILED) {
                   s5 = [s5, s6];
                   s4 = s5;
                 } else {
                   peg$currPos = s4;
                   s4 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             }
           } else {
             s3 = peg$FAILED;
           }
           if (s3 !== peg$FAILED) {
             s4 = peg$parseDEDENT();
             if (s4 !== peg$FAILED) {
               peg$savedPos = s0;
               s1 = peg$c54(s3);
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     }

     return s0;
   }

   function peg$parsenamedexpr_test() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parsetest();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseCOLONEQUAL();
       if (s3 !== peg$FAILED) {
         s4 = peg$parsetest();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c55(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetest() {
     var s0, s1, s2, s3, s4, s5, s6, s7;

     s0 = peg$currPos;
     s1 = peg$parseor_test();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseIF();
       if (s3 !== peg$FAILED) {
         s4 = peg$parse__();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseor_test();
           if (s5 !== peg$FAILED) {
             s6 = peg$parseELSE();
             if (s6 !== peg$FAILED) {
               s7 = peg$parsetest();
               if (s7 !== peg$FAILED) {
                 s3 = [s3, s4, s5, s6, s7];
                 s2 = s3;
               } else {
                 peg$currPos = s2;
                 s2 = peg$FAILED;
               }
             } else {
               peg$currPos = s2;
               s2 = peg$FAILED;
             }
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c56(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parselambdef();
       if (s1 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c57(s1);
       }
       s0 = s1;
     }

     return s0;
   }

   function peg$parsetest_nocond() {
     var s0;

     s0 = peg$parseor_test();
     if (s0 === peg$FAILED) {
       s0 = peg$parselambdef_nocond();
     }

     return s0;
   }

   function peg$parselambdef() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseLAMBDA();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsevarargslist();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOLON();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsetest();
           if (s4 !== peg$FAILED) {
             s1 = [s1, s2, s3, s4];
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parselambdef_nocond() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseLAMBDA();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsevarargslist();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOLON();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsetest_nocond();
           if (s4 !== peg$FAILED) {
             s1 = [s1, s2, s3, s4];
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseor_test() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseand_test();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseOR();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseand_test();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseOR();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseand_test();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c58(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseand_test() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsenot_test();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseAND();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsenot_test();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseAND();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsenot_test();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c59(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsenot_test() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseNOT();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsenot_test();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c60(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parsecomparison();
       if (s1 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c61(s1);
       }
       s0 = s1;
     }

     return s0;
   }

   function peg$parsecomparison() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseexpr();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parsecomp_op();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseexpr();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parsecomp_op();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseexpr();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c62(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsecomp_op() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parseDOUBLE_EQUALS();
     if (s1 === peg$FAILED) {
       s1 = peg$parseGREATEREQUAL();
       if (s1 === peg$FAILED) {
         s1 = peg$parseLESSEQUAL();
         if (s1 === peg$FAILED) {
           s1 = peg$parseNOTEQUAL_EXCEL();
           if (s1 === peg$FAILED) {
             s1 = peg$parseLESS();
             if (s1 === peg$FAILED) {
               s1 = peg$parseGREATER();
               if (s1 === peg$FAILED) {
                 s1 = peg$parseNOTEQUAL();
                 if (s1 === peg$FAILED) {
                   s1 = peg$parseIN();
                   if (s1 === peg$FAILED) {
                     s1 = peg$currPos;
                     s2 = peg$parseNOT();
                     if (s2 !== peg$FAILED) {
                       s3 = peg$parseIN();
                       if (s3 !== peg$FAILED) {
                         s2 = [s2, s3];
                         s1 = s2;
                       } else {
                         peg$currPos = s1;
                         s1 = peg$FAILED;
                       }
                     } else {
                       peg$currPos = s1;
                       s1 = peg$FAILED;
                     }
                     if (s1 === peg$FAILED) {
                       s1 = peg$parseIS();
                       if (s1 === peg$FAILED) {
                         s1 = peg$currPos;
                         s2 = peg$parseIS();
                         if (s2 !== peg$FAILED) {
                           s3 = peg$parseNOT();
                           if (s3 !== peg$FAILED) {
                             s2 = [s2, s3];
                             s1 = s2;
                           } else {
                             peg$currPos = s1;
                             s1 = peg$FAILED;
                           }
                         } else {
                           peg$currPos = s1;
                           s1 = peg$FAILED;
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c63(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsestar_expr() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseSTAR();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseexpr();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c64(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseexpr() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsexor_expr();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseVBAR();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsexor_expr();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseVBAR();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsexor_expr();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c65(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsexor_expr() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseand_expr();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCIRCUMFLEX();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseand_expr();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCIRCUMFLEX();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseand_expr();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c66(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseand_expr() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseshift_expr();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseAMPER();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseshift_expr();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseAMPER();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseshift_expr();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c67(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseshift_expr() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsearith_expr();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseLEFTSHIFT();
       if (s4 === peg$FAILED) {
         s4 = peg$parseRIGHTSHIFT();
       }
       if (s4 !== peg$FAILED) {
         s5 = peg$parsearith_expr();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseLEFTSHIFT();
         if (s4 === peg$FAILED) {
           s4 = peg$parseRIGHTSHIFT();
         }
         if (s4 !== peg$FAILED) {
           s5 = peg$parsearith_expr();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c68(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsearith_expr() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseterm();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parsePLUS();
       if (s4 === peg$FAILED) {
         s4 = peg$parseMINUS();
       }
       if (s4 !== peg$FAILED) {
         s5 = peg$parseterm();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parsePLUS();
         if (s4 === peg$FAILED) {
           s4 = peg$parseMINUS();
         }
         if (s4 !== peg$FAILED) {
           s5 = peg$parseterm();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c68(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseterm() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsefactor();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseSTAR();
       if (s4 === peg$FAILED) {
         s4 = peg$parseAT();
         if (s4 === peg$FAILED) {
           s4 = peg$parseSLASH();
           if (s4 === peg$FAILED) {
             s4 = peg$parsePERCENT();
             if (s4 === peg$FAILED) {
               s4 = peg$parseDOUBLESLASH();
             }
           }
         }
       }
       if (s4 !== peg$FAILED) {
         s5 = peg$parsefactor();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseSTAR();
         if (s4 === peg$FAILED) {
           s4 = peg$parseAT();
           if (s4 === peg$FAILED) {
             s4 = peg$parseSLASH();
             if (s4 === peg$FAILED) {
               s4 = peg$parsePERCENT();
               if (s4 === peg$FAILED) {
                 s4 = peg$parseDOUBLESLASH();
               }
             }
           }
         }
         if (s4 !== peg$FAILED) {
           s5 = peg$parsefactor();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c68(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsefactor() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parsePLUS();
     if (s1 === peg$FAILED) {
       s1 = peg$parseMINUS();
       if (s1 === peg$FAILED) {
         s1 = peg$parseTILDE();
       }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parsefactor();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c69(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parsepower();
       if (s1 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c70(s1);
       }
       s0 = s1;
     }

     return s0;
   }

   function peg$parsepower() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseatom_expr();
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseDOUBLESTAR();
       if (s3 !== peg$FAILED) {
         s4 = peg$parsefactor();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c71(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseatom_expr() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseAWAIT();
     if (s1 === peg$FAILED) {
       s1 = null;
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parseatom();
       if (s2 !== peg$FAILED) {
         s3 = [];
         s4 = peg$parsetrailer();
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$parsetrailer();
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c72(s1, s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseatom() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$currPos;
     s2 = peg$parseOPEN_PAREN();
     if (s2 !== peg$FAILED) {
       s3 = peg$parseyield_expr();
       if (s3 === peg$FAILED) {
         s3 = peg$parsetestlist_comp();
       }
       if (s3 === peg$FAILED) {
         s3 = null;
       }
       if (s3 !== peg$FAILED) {
         s4 = peg$parseCLOSE_PAREN();
         if (s4 !== peg$FAILED) {
           peg$savedPos = s1;
           s2 = peg$c73(s3);
           s1 = s2;
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 === peg$FAILED) {
       s1 = peg$currPos;
       s2 = peg$parseOPEN_SQUARE_BRACKET();
       if (s2 !== peg$FAILED) {
         s3 = peg$parsetestlist_comp();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCLOSE_SQUARE_BRACKET();
           if (s4 !== peg$FAILED) {
             peg$savedPos = s1;
             s2 = peg$c74(s3);
             s1 = s2;
           } else {
             peg$currPos = s1;
             s1 = peg$FAILED;
           }
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
       if (s1 === peg$FAILED) {
         s1 = peg$currPos;
         s2 = peg$parseOPEN_CURLY_BRACKET();
         if (s2 !== peg$FAILED) {
           s3 = peg$parsedictorsetmaker();
           if (s3 === peg$FAILED) {
             s3 = null;
           }
           if (s3 !== peg$FAILED) {
             s4 = peg$parseCLOSE_CURLY_BRACKET();
             if (s4 !== peg$FAILED) {
               peg$savedPos = s1;
               s2 = peg$c75(s3);
               s1 = s2;
             } else {
               peg$currPos = s1;
               s1 = peg$FAILED;
             }
           } else {
             peg$currPos = s1;
             s1 = peg$FAILED;
           }
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
         if (s1 === peg$FAILED) {
           s1 = peg$parseNAME();
           if (s1 === peg$FAILED) {
             s1 = peg$parseNUMBER();
             if (s1 === peg$FAILED) {
               s1 = [];
               s2 = peg$parseSTRING();
               if (s2 !== peg$FAILED) {
                 while (s2 !== peg$FAILED) {
                   s1.push(s2);
                   s2 = peg$parseSTRING();
                 }
               } else {
                 s1 = peg$FAILED;
               }
               if (s1 === peg$FAILED) {
                 s1 = peg$parseELLIPSIS();
                 if (s1 === peg$FAILED) {
                   s1 = peg$parseNONE_LITERAL();
                   if (s1 === peg$FAILED) {
                     s1 = peg$parseTRUE();
                     if (s1 === peg$FAILED) {
                       s1 = peg$parseFALSE();
                     }
                   }
                 }
               }
             }
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c76(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsetestlist_comp() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parsenamedexpr_test();
     if (s1 === peg$FAILED) {
       s1 = peg$parsestar_expr();
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parsecomp_for();
       if (s2 === peg$FAILED) {
         s2 = peg$currPos;
         s3 = [];
         s4 = peg$currPos;
         s5 = peg$parseCOMMA();
         if (s5 !== peg$FAILED) {
           s6 = peg$parsenamedexpr_test();
           if (s6 === peg$FAILED) {
             s6 = peg$parsestar_expr();
           }
           if (s6 !== peg$FAILED) {
             peg$savedPos = s4;
             s5 = peg$c77(s1, s6);
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parsenamedexpr_test();
             if (s6 === peg$FAILED) {
               s6 = peg$parsestar_expr();
             }
             if (s6 !== peg$FAILED) {
               peg$savedPos = s4;
               s5 = peg$c77(s1, s6);
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCOMMA();
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             peg$savedPos = s2;
             s3 = peg$c78(s1, s3);
             s2 = s3;
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c79(s1, s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetrailer() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parseOPEN_PAREN();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsearglist();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCLOSE_PAREN();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c80(s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parseOPEN_SQUARE_BRACKET();
       if (s1 !== peg$FAILED) {
         s2 = peg$parsesubscriptlist();
         if (s2 !== peg$FAILED) {
           s3 = peg$parseCLOSE_SQUARE_BRACKET();
           if (s3 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c81(s2);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
       if (s0 === peg$FAILED) {
         s0 = peg$currPos;
         s1 = peg$parseDOT();
         if (s1 !== peg$FAILED) {
           s2 = peg$parseNAME();
           if (s2 !== peg$FAILED) {
             peg$savedPos = s0;
             s1 = peg$c82(s2);
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       }
     }

     return s0;
   }

   function peg$parsesubscriptlist() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsesubscript();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsesubscript();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsesubscript();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c83(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsesubscript() {
     var s0, s1, s2, s3, s4;

     s0 = peg$parsetest();
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parsetest();
       if (s1 === peg$FAILED) {
         s1 = null;
       }
       if (s1 !== peg$FAILED) {
         s2 = peg$parseCOLON();
         if (s2 !== peg$FAILED) {
           s3 = peg$parsetest();
           if (s3 === peg$FAILED) {
             s3 = null;
           }
           if (s3 !== peg$FAILED) {
             s4 = peg$parsesliceop();
             if (s4 === peg$FAILED) {
               s4 = null;
             }
             if (s4 !== peg$FAILED) {
               peg$savedPos = s0;
               s1 = peg$c84(s1, s3, s4);
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     }

     return s0;
   }

   function peg$parsesliceop() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseCOLON();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetest();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c85(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseexprlist() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseexpr();
     if (s1 === peg$FAILED) {
       s1 = peg$parsestar_expr();
     }
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseexpr();
         if (s5 === peg$FAILED) {
           s5 = peg$parsestar_expr();
         }
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseexpr();
           if (s5 === peg$FAILED) {
             s5 = peg$parsestar_expr();
           }
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c86(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetestlist() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parsetest();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsetest();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsetest();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c87(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsedictorsetmaker() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

     s0 = peg$currPos;
     s1 = peg$currPos;
     s2 = peg$parsetest();
     if (s2 !== peg$FAILED) {
       s3 = peg$parseCOLON();
       if (s3 !== peg$FAILED) {
         s4 = peg$parsetest();
         if (s4 !== peg$FAILED) {
           s2 = [s2, s3, s4];
           s1 = s2;
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 === peg$FAILED) {
       s1 = peg$currPos;
       s2 = peg$parseDOUBLESTAR();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseexpr();
         if (s3 !== peg$FAILED) {
           s2 = [s2, s3];
           s1 = s2;
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parsecomp_for();
       if (s2 === peg$FAILED) {
         s2 = peg$currPos;
         s3 = [];
         s4 = peg$currPos;
         s5 = peg$parseCOMMA();
         if (s5 !== peg$FAILED) {
           s6 = peg$currPos;
           s7 = peg$parsetest();
           if (s7 !== peg$FAILED) {
             s8 = peg$parseCOLON();
             if (s8 !== peg$FAILED) {
               s9 = peg$parsetest();
               if (s9 !== peg$FAILED) {
                 s7 = [s7, s8, s9];
                 s6 = s7;
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             } else {
               peg$currPos = s6;
               s6 = peg$FAILED;
             }
           } else {
             peg$currPos = s6;
             s6 = peg$FAILED;
           }
           if (s6 === peg$FAILED) {
             s6 = peg$currPos;
             s7 = peg$parseDOUBLESTAR();
             if (s7 !== peg$FAILED) {
               s8 = peg$parseexpr();
               if (s8 !== peg$FAILED) {
                 s7 = [s7, s8];
                 s6 = s7;
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             } else {
               peg$currPos = s6;
               s6 = peg$FAILED;
             }
           }
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$currPos;
             s7 = peg$parsetest();
             if (s7 !== peg$FAILED) {
               s8 = peg$parseCOLON();
               if (s8 !== peg$FAILED) {
                 s9 = peg$parsetest();
                 if (s9 !== peg$FAILED) {
                   s7 = [s7, s8, s9];
                   s6 = s7;
                 } else {
                   peg$currPos = s6;
                   s6 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             } else {
               peg$currPos = s6;
               s6 = peg$FAILED;
             }
             if (s6 === peg$FAILED) {
               s6 = peg$currPos;
               s7 = peg$parseDOUBLESTAR();
               if (s7 !== peg$FAILED) {
                 s8 = peg$parseexpr();
                 if (s8 !== peg$FAILED) {
                   s7 = [s7, s8];
                   s6 = s7;
                 } else {
                   peg$currPos = s6;
                   s6 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s6;
                 s6 = peg$FAILED;
               }
             }
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCOMMA();
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             s3 = [s3, s4];
             s2 = s3;
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parsetest();
       if (s1 === peg$FAILED) {
         s1 = peg$parsestar_expr();
       }
       if (s1 !== peg$FAILED) {
         s2 = peg$parsecomp_for();
         if (s2 === peg$FAILED) {
           s2 = peg$currPos;
           s3 = [];
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parsetest();
             if (s6 === peg$FAILED) {
               s6 = peg$parsestar_expr();
             }
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           while (s4 !== peg$FAILED) {
             s3.push(s4);
             s4 = peg$currPos;
             s5 = peg$parseCOMMA();
             if (s5 !== peg$FAILED) {
               s6 = peg$parsetest();
               if (s6 === peg$FAILED) {
                 s6 = peg$parsestar_expr();
               }
               if (s6 !== peg$FAILED) {
                 s5 = [s5, s6];
                 s4 = s5;
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           }
           if (s3 !== peg$FAILED) {
             s4 = peg$parseCOMMA();
             if (s4 === peg$FAILED) {
               s4 = null;
             }
             if (s4 !== peg$FAILED) {
               s3 = [s3, s4];
               s2 = s3;
             } else {
               peg$currPos = s2;
               s2 = peg$FAILED;
             }
           } else {
             peg$currPos = s2;
             s2 = peg$FAILED;
           }
         }
         if (s2 !== peg$FAILED) {
           s1 = [s1, s2];
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     }

     return s0;
   }

   function peg$parseclassdef() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$parseCLASS();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseNAME();
       if (s2 !== peg$FAILED) {
         s3 = peg$currPos;
         s4 = peg$parseOPEN_PAREN();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsearglist();
           if (s5 === peg$FAILED) {
             s5 = null;
           }
           if (s5 !== peg$FAILED) {
             s6 = peg$parseCLOSE_PAREN();
             if (s6 !== peg$FAILED) {
               s4 = [s4, s5, s6];
               s3 = s4;
             } else {
               peg$currPos = s3;
               s3 = peg$FAILED;
             }
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseCOLON();
           if (s4 !== peg$FAILED) {
             s5 = peg$parsesuite();
             if (s5 !== peg$FAILED) {
               peg$savedPos = s0;
               s1 = peg$c88(s2, s3, s5);
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsearglist() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseargument();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parseargument();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parseargument();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parseCOMMA();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c89(s1, s2);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseargument() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$currPos;
     s2 = peg$parsetest();
     if (s2 !== peg$FAILED) {
       s3 = peg$parseCOLONEQUAL();
       if (s3 !== peg$FAILED) {
         s4 = peg$parsetest();
         if (s4 !== peg$FAILED) {
           peg$savedPos = s1;
           s2 = peg$c90(s2, s3, s4);
           s1 = s2;
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 === peg$FAILED) {
       s1 = peg$currPos;
       s2 = peg$parsetest();
       if (s2 !== peg$FAILED) {
         s3 = peg$parsecomp_for();
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s1;
           s2 = peg$c91(s2, s3);
           s1 = s2;
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
       if (s1 === peg$FAILED) {
         s1 = peg$currPos;
         s2 = peg$parsetest();
         if (s2 !== peg$FAILED) {
           s3 = peg$parseEQUALS();
           if (s3 !== peg$FAILED) {
             s4 = peg$parsetest();
             if (s4 !== peg$FAILED) {
               peg$savedPos = s1;
               s2 = peg$c92(s2, s3, s4);
               s1 = s2;
             } else {
               peg$currPos = s1;
               s1 = peg$FAILED;
             }
           } else {
             peg$currPos = s1;
             s1 = peg$FAILED;
           }
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
         if (s1 === peg$FAILED) {
           s1 = peg$currPos;
           s2 = peg$parseDOUBLESTAR();
           if (s2 !== peg$FAILED) {
             s3 = peg$parsetfpdef();
             if (s3 !== peg$FAILED) {
               peg$savedPos = s1;
               s2 = peg$c93(s2, s3);
               s1 = s2;
             } else {
               peg$currPos = s1;
               s1 = peg$FAILED;
             }
           } else {
             peg$currPos = s1;
             s1 = peg$FAILED;
           }
           if (s1 === peg$FAILED) {
             s1 = peg$currPos;
             s2 = peg$parseSTAR();
             if (s2 !== peg$FAILED) {
               s3 = peg$parsetfpdef();
               if (s3 !== peg$FAILED) {
                 peg$savedPos = s1;
                 s2 = peg$c94(s2, s3);
                 s1 = s2;
               } else {
                 peg$currPos = s1;
                 s1 = peg$FAILED;
               }
             } else {
               peg$currPos = s1;
               s1 = peg$FAILED;
             }
             if (s1 === peg$FAILED) {
               s1 = peg$currPos;
               s2 = peg$parseSTAR();
               if (s2 !== peg$FAILED) {
                 peg$savedPos = s1;
                 s2 = peg$c95();
               }
               s1 = s2;
             }
           }
         }
       }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$currPos;
       s3 = peg$parseCOLON();
       if (s3 !== peg$FAILED) {
         s4 = peg$parsetest();
         if (s4 !== peg$FAILED) {
           s3 = [s3, s4];
           s2 = s3;
         } else {
           peg$currPos = s2;
           s2 = peg$FAILED;
         }
       } else {
         peg$currPos = s2;
         s2 = peg$FAILED;
       }
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$currPos;
         s4 = peg$parseEQUALS();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsetest();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c96(s1, s2, s3);
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsecomp_iter() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsecomp_for();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c11(s1);
     }
     s0 = s1;
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parsecomp_if();
       if (s1 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c11(s1);
       }
       s0 = s1;
     }

     return s0;
   }

   function peg$parsesync_comp_for() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parseFOR();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseexprlist();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseIN();
         if (s3 !== peg$FAILED) {
           s4 = peg$parseor_test();
           if (s4 !== peg$FAILED) {
             s5 = peg$parsecomp_iter();
             if (s5 === peg$FAILED) {
               s5 = null;
             }
             if (s5 !== peg$FAILED) {
               s1 = [s1, s2, s3, s4, s5];
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsecomp_for() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseASYNC();
     if (s1 === peg$FAILED) {
       s1 = null;
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parsesync_comp_for();
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsecomp_if() {
     var s0, s1, s2, s3, s4;

     s0 = peg$currPos;
     s1 = peg$parseIF();
     if (s1 !== peg$FAILED) {
       s2 = peg$parse__();
       if (s2 !== peg$FAILED) {
         s3 = peg$parsetest_nocond();
         if (s3 !== peg$FAILED) {
           s4 = peg$parsecomp_iter();
           if (s4 === peg$FAILED) {
             s4 = null;
           }
           if (s4 !== peg$FAILED) {
             s1 = [s1, s2, s3, s4];
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseyield_expr() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseYIELD();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseyield_arg();
       if (s2 === peg$FAILED) {
         s2 = null;
       }
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseyield_arg() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parseFROM();
     if (s1 !== peg$FAILED) {
       s2 = peg$parsetest();
       if (s2 !== peg$FAILED) {
         s1 = [s1, s2];
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$parsetestlist_star_expr();
     }

     return s0;
   }

   function peg$parsecomment() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parseCOMMENT();
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c97(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsetypelist() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

     s0 = peg$currPos;
     s1 = peg$parsetest();
     if (s1 !== peg$FAILED) {
       s2 = [];
       s3 = peg$currPos;
       s4 = peg$parseCOMMA();
       if (s4 !== peg$FAILED) {
         s5 = peg$parsetest();
         if (s5 !== peg$FAILED) {
           s4 = [s4, s5];
           s3 = s4;
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       } else {
         peg$currPos = s3;
         s3 = peg$FAILED;
       }
       while (s3 !== peg$FAILED) {
         s2.push(s3);
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$parsetest();
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$currPos;
         s4 = peg$parseCOMMA();
         if (s4 !== peg$FAILED) {
           s5 = peg$currPos;
           s6 = peg$parseSTAR();
           if (s6 !== peg$FAILED) {
             s7 = peg$parsetest();
             if (s7 === peg$FAILED) {
               s7 = null;
             }
             if (s7 !== peg$FAILED) {
               s8 = [];
               s9 = peg$currPos;
               s10 = peg$parseCOMMA();
               if (s10 !== peg$FAILED) {
                 s11 = peg$parsetest();
                 if (s11 !== peg$FAILED) {
                   s10 = [s10, s11];
                   s9 = s10;
                 } else {
                   peg$currPos = s9;
                   s9 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s9;
                 s9 = peg$FAILED;
               }
               while (s9 !== peg$FAILED) {
                 s8.push(s9);
                 s9 = peg$currPos;
                 s10 = peg$parseCOMMA();
                 if (s10 !== peg$FAILED) {
                   s11 = peg$parsetest();
                   if (s11 !== peg$FAILED) {
                     s10 = [s10, s11];
                     s9 = s10;
                   } else {
                     peg$currPos = s9;
                     s9 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s9;
                   s9 = peg$FAILED;
                 }
               }
               if (s8 !== peg$FAILED) {
                 s9 = peg$currPos;
                 s10 = peg$parseCOMMA();
                 if (s10 !== peg$FAILED) {
                   s11 = peg$parseDOUBLESTAR();
                   if (s11 !== peg$FAILED) {
                     s12 = peg$parsetest();
                     if (s12 !== peg$FAILED) {
                       s10 = [s10, s11, s12];
                       s9 = s10;
                     } else {
                       peg$currPos = s9;
                       s9 = peg$FAILED;
                     }
                   } else {
                     peg$currPos = s9;
                     s9 = peg$FAILED;
                   }
                 } else {
                   peg$currPos = s9;
                   s9 = peg$FAILED;
                 }
                 if (s9 === peg$FAILED) {
                   s9 = null;
                 }
                 if (s9 !== peg$FAILED) {
                   s6 = [s6, s7, s8, s9];
                   s5 = s6;
                 } else {
                   peg$currPos = s5;
                   s5 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s5;
                 s5 = peg$FAILED;
               }
             } else {
               peg$currPos = s5;
               s5 = peg$FAILED;
             }
           } else {
             peg$currPos = s5;
             s5 = peg$FAILED;
           }
           if (s5 === peg$FAILED) {
             s5 = peg$currPos;
             s6 = peg$parseDOUBLESTAR();
             if (s6 !== peg$FAILED) {
               s7 = peg$parsetest();
               if (s7 !== peg$FAILED) {
                 s6 = [s6, s7];
                 s5 = s6;
               } else {
                 peg$currPos = s5;
                 s5 = peg$FAILED;
               }
             } else {
               peg$currPos = s5;
               s5 = peg$FAILED;
             }
           }
           if (s5 === peg$FAILED) {
             s5 = null;
           }
           if (s5 !== peg$FAILED) {
             s4 = [s4, s5];
             s3 = s4;
           } else {
             peg$currPos = s3;
             s3 = peg$FAILED;
           }
         } else {
           peg$currPos = s3;
           s3 = peg$FAILED;
         }
         if (s3 === peg$FAILED) {
           s3 = null;
         }
         if (s3 !== peg$FAILED) {
           s1 = [s1, s2, s3];
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parseSTAR();
       if (s1 !== peg$FAILED) {
         s2 = peg$parsetest();
         if (s2 === peg$FAILED) {
           s2 = null;
         }
         if (s2 !== peg$FAILED) {
           s3 = [];
           s4 = peg$currPos;
           s5 = peg$parseCOMMA();
           if (s5 !== peg$FAILED) {
             s6 = peg$parsetest();
             if (s6 !== peg$FAILED) {
               s5 = [s5, s6];
               s4 = s5;
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           while (s4 !== peg$FAILED) {
             s3.push(s4);
             s4 = peg$currPos;
             s5 = peg$parseCOMMA();
             if (s5 !== peg$FAILED) {
               s6 = peg$parsetest();
               if (s6 !== peg$FAILED) {
                 s5 = [s5, s6];
                 s4 = s5;
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           }
           if (s3 !== peg$FAILED) {
             s4 = peg$currPos;
             s5 = peg$parseCOMMA();
             if (s5 !== peg$FAILED) {
               s6 = peg$parseDOUBLESTAR();
               if (s6 !== peg$FAILED) {
                 s7 = peg$parsetest();
                 if (s7 !== peg$FAILED) {
                   s5 = [s5, s6, s7];
                   s4 = s5;
                 } else {
                   peg$currPos = s4;
                   s4 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
             if (s4 === peg$FAILED) {
               s4 = null;
             }
             if (s4 !== peg$FAILED) {
               s1 = [s1, s2, s3, s4];
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
       if (s0 === peg$FAILED) {
         s0 = peg$currPos;
         s1 = peg$parseDOUBLESTAR();
         if (s1 !== peg$FAILED) {
           s2 = peg$parsetest();
           if (s2 !== peg$FAILED) {
             s1 = [s1, s2];
             s0 = s1;
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       }
     }

     return s0;
   }

   function peg$parseSAMEDENT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$currPos;
     s2 = peg$parsetabs();
     if (s2 !== peg$FAILED) {
       peg$savedPos = peg$currPos;
       s3 = peg$c98(s2);
       if (s3) {
         s3 = void 0;
       } else {
         s3 = peg$FAILED;
       }
       if (s3 !== peg$FAILED) {
         s2 = [s2, s3];
         s1 = s2;
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c99();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseINDENT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     peg$silentFails++;
     s1 = peg$currPos;
     s2 = peg$parsetabs();
     if (s2 !== peg$FAILED) {
       peg$savedPos = peg$currPos;
       s3 = peg$c100(s2);
       if (s3) {
         s3 = void 0;
       } else {
         s3 = peg$FAILED;
       }
       if (s3 !== peg$FAILED) {
         peg$savedPos = s1;
         s2 = peg$c101(s2);
         s1 = s2;
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     peg$silentFails--;
     if (s1 !== peg$FAILED) {
       peg$currPos = s0;
       s0 = void 0;
     } else {
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDEDENT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     peg$silentFails++;
     s1 = peg$currPos;
     s2 = peg$parsetabs();
     if (s2 !== peg$FAILED) {
       peg$savedPos = peg$currPos;
       s3 = peg$c102(s2);
       if (s3) {
         s3 = void 0;
       } else {
         s3 = peg$FAILED;
       }
       if (s3 !== peg$FAILED) {
         peg$savedPos = s1;
         s2 = peg$c101(s2);
         s1 = s2;
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     peg$silentFails--;
     if (s1 !== peg$FAILED) {
       peg$currPos = s0;
       s0 = void 0;
     } else {
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsetabs() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = [];
     s2 = peg$parsetab();
     while (s2 !== peg$FAILED) {
       s1.push(s2);
       s2 = peg$parsetab();
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c11(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsetab() {
     var s0, s1;

     s0 = peg$currPos;
     if (input.charCodeAt(peg$currPos) === 9) {
       s1 = peg$c103;
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c104); }
     }
     if (s1 === peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c105) {
         s1 = peg$c105;
         peg$currPos += 2;
       } else {
         s1 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c106); }
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c11(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseSTRING() {
     var s0, s1, s2, s3, s4, s5, s6;

     s0 = peg$currPos;
     s1 = peg$currPos;
     s2 = peg$parseDOUBLE_QUOTE();
     if (s2 !== peg$FAILED) {
       s3 = [];
       s4 = peg$parsedoubleqstringitem();
       while (s4 !== peg$FAILED) {
         s3.push(s4);
         s4 = peg$parsedoubleqstringitem();
       }
       if (s3 !== peg$FAILED) {
         s4 = peg$parseDOUBLE_QUOTE();
         if (s4 !== peg$FAILED) {
           s5 = peg$currPos;
           peg$silentFails++;
           s6 = peg$parseDOUBLE_QUOTE();
           peg$silentFails--;
           if (s6 === peg$FAILED) {
             s5 = void 0;
           } else {
             peg$currPos = s5;
             s5 = peg$FAILED;
           }
           if (s5 !== peg$FAILED) {
             s2 = [s2, s3, s4, s5];
             s1 = s2;
           } else {
             peg$currPos = s1;
             s1 = peg$FAILED;
           }
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 === peg$FAILED) {
       s1 = peg$currPos;
       s2 = peg$parseSINGLE_QUOTE();
       if (s2 !== peg$FAILED) {
         s3 = [];
         s4 = peg$parsesingleqstringitem();
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           s4 = peg$parsesingleqstringitem();
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parseSINGLE_QUOTE();
           if (s4 !== peg$FAILED) {
             s2 = [s2, s3, s4];
             s1 = s2;
           } else {
             peg$currPos = s1;
             s1 = peg$FAILED;
           }
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c107(s1);
     }
     s0 = s1;
     if (s0 === peg$FAILED) {
       s0 = peg$currPos;
       s1 = peg$parseMULTILINE_STRING();
       if (s1 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c108(s1);
       }
       s0 = s1;
     }

     return s0;
   }

   function peg$parsedoubleqstringitem() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsedoubleqstringchar();
     if (s1 === peg$FAILED) {
       s1 = peg$parseescapeseq();
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c109(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsesingleqstringitem() {
     var s0, s1;

     s0 = peg$currPos;
     s1 = peg$parsesingleqstringchar();
     if (s1 === peg$FAILED) {
       s1 = peg$parseescapeseq();
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c109(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsedoubleqstringchar() {
     var s0, s1;

     s0 = peg$currPos;
     if (peg$c110.test(input.charAt(peg$currPos))) {
       s1 = input.charAt(peg$currPos);
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c111); }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c63(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parsesingleqstringchar() {
     var s0, s1;

     s0 = peg$currPos;
     if (peg$c112.test(input.charAt(peg$currPos))) {
       s1 = input.charAt(peg$currPos);
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c113); }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c63(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseescapeseq() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$currPos;
     if (input.charCodeAt(peg$currPos) === 92) {
       s2 = peg$c114;
       peg$currPos++;
     } else {
       s2 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c115); }
     }
     if (s2 !== peg$FAILED) {
       if (peg$c116.test(input.charAt(peg$currPos))) {
         s3 = input.charAt(peg$currPos);
         peg$currPos++;
       } else {
         s3 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c117); }
       }
       if (s3 !== peg$FAILED) {
         s2 = [s2, s3];
         s1 = s2;
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c118(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseMULTILINE_STRING() {
     var s0, s1, s2, s3, s4, s5, s6, s7;

     s0 = peg$currPos;
     s1 = peg$parseDOUBLE_QUOTE();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseDOUBLE_QUOTE();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseDOUBLE_QUOTE();
         if (s3 !== peg$FAILED) {
           s4 = [];
           s5 = peg$parseMULTILINE_STRING_CHARACTER();
           if (s5 !== peg$FAILED) {
             while (s5 !== peg$FAILED) {
               s4.push(s5);
               s5 = peg$parseMULTILINE_STRING_CHARACTER();
             }
           } else {
             s4 = peg$FAILED;
           }
           if (s4 !== peg$FAILED) {
             s5 = peg$parseDOUBLE_QUOTE();
             if (s5 !== peg$FAILED) {
               s6 = peg$parseDOUBLE_QUOTE();
               if (s6 !== peg$FAILED) {
                 s7 = peg$parseDOUBLE_QUOTE();
                 if (s7 !== peg$FAILED) {
                   peg$savedPos = s0;
                   s1 = peg$c119(s4);
                   s0 = s1;
                 } else {
                   peg$currPos = s0;
                   s0 = peg$FAILED;
                 }
               } else {
                 peg$currPos = s0;
                 s0 = peg$FAILED;
               }
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseMULTILINE_STRING_BORDER() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parseDOUBLE_QUOTE();
     if (s1 !== peg$FAILED) {
       s2 = peg$parseDOUBLE_QUOTE();
       if (s2 !== peg$FAILED) {
         s3 = peg$parseDOUBLE_QUOTE();
         if (s3 !== peg$FAILED) {
           s1 = [s1, s2, s3];
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseMULTILINE_STRING_CHARACTER() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$currPos;
     peg$silentFails++;
     s2 = peg$parseMULTILINE_STRING_BORDER();
     peg$silentFails--;
     if (s2 === peg$FAILED) {
       s1 = void 0;
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 !== peg$FAILED) {
       if (input.length > peg$currPos) {
         s2 = input.charAt(peg$currPos);
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c120); }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c121(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNUMBER() {
     var s0, s1, s2, s3, s4, s5, s6, s7, s8;

     s0 = peg$currPos;
     s1 = peg$currPos;
     if (input.charCodeAt(peg$currPos) === 45) {
       s2 = peg$c122;
       peg$currPos++;
     } else {
       s2 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c123); }
     }
     if (s2 === peg$FAILED) {
       s2 = null;
     }
     if (s2 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c124) {
         s3 = peg$c124;
         peg$currPos += 2;
       } else {
         s3 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c125); }
       }
       if (s3 === peg$FAILED) {
         if (input.substr(peg$currPos, 2) === peg$c126) {
           s3 = peg$c126;
           peg$currPos += 2;
         } else {
           s3 = peg$FAILED;
           if (peg$silentFails === 0) { peg$fail(peg$c127); }
         }
       }
       if (s3 === peg$FAILED) {
         s3 = null;
       }
       if (s3 !== peg$FAILED) {
         s4 = peg$currPos;
         s5 = peg$parseDOT();
         if (s5 !== peg$FAILED) {
           s6 = [];
           if (peg$c128.test(input.charAt(peg$currPos))) {
             s7 = input.charAt(peg$currPos);
             peg$currPos++;
           } else {
             s7 = peg$FAILED;
             if (peg$silentFails === 0) { peg$fail(peg$c129); }
           }
           if (s7 !== peg$FAILED) {
             while (s7 !== peg$FAILED) {
               s6.push(s7);
               if (peg$c128.test(input.charAt(peg$currPos))) {
                 s7 = input.charAt(peg$currPos);
                 peg$currPos++;
               } else {
                 s7 = peg$FAILED;
                 if (peg$silentFails === 0) { peg$fail(peg$c129); }
               }
             }
           } else {
             s6 = peg$FAILED;
           }
           if (s6 !== peg$FAILED) {
             s5 = [s5, s6];
             s4 = s5;
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
         } else {
           peg$currPos = s4;
           s4 = peg$FAILED;
         }
         if (s4 === peg$FAILED) {
           s4 = peg$currPos;
           s5 = [];
           if (peg$c128.test(input.charAt(peg$currPos))) {
             s6 = input.charAt(peg$currPos);
             peg$currPos++;
           } else {
             s6 = peg$FAILED;
             if (peg$silentFails === 0) { peg$fail(peg$c129); }
           }
           if (s6 !== peg$FAILED) {
             while (s6 !== peg$FAILED) {
               s5.push(s6);
               if (peg$c128.test(input.charAt(peg$currPos))) {
                 s6 = input.charAt(peg$currPos);
                 peg$currPos++;
               } else {
                 s6 = peg$FAILED;
                 if (peg$silentFails === 0) { peg$fail(peg$c129); }
               }
             }
           } else {
             s5 = peg$FAILED;
           }
           if (s5 !== peg$FAILED) {
             s6 = peg$parseDOT();
             if (s6 !== peg$FAILED) {
               s7 = [];
               if (peg$c128.test(input.charAt(peg$currPos))) {
                 s8 = input.charAt(peg$currPos);
                 peg$currPos++;
               } else {
                 s8 = peg$FAILED;
                 if (peg$silentFails === 0) { peg$fail(peg$c129); }
               }
               if (s8 !== peg$FAILED) {
                 while (s8 !== peg$FAILED) {
                   s7.push(s8);
                   if (peg$c128.test(input.charAt(peg$currPos))) {
                     s8 = input.charAt(peg$currPos);
                     peg$currPos++;
                   } else {
                     s8 = peg$FAILED;
                     if (peg$silentFails === 0) { peg$fail(peg$c129); }
                   }
                 }
               } else {
                 s7 = peg$FAILED;
               }
               if (s7 !== peg$FAILED) {
                 s5 = [s5, s6, s7];
                 s4 = s5;
               } else {
                 peg$currPos = s4;
                 s4 = peg$FAILED;
               }
             } else {
               peg$currPos = s4;
               s4 = peg$FAILED;
             }
           } else {
             peg$currPos = s4;
             s4 = peg$FAILED;
           }
           if (s4 === peg$FAILED) {
             s4 = [];
             if (peg$c128.test(input.charAt(peg$currPos))) {
               s5 = input.charAt(peg$currPos);
               peg$currPos++;
             } else {
               s5 = peg$FAILED;
               if (peg$silentFails === 0) { peg$fail(peg$c129); }
             }
             if (s5 !== peg$FAILED) {
               while (s5 !== peg$FAILED) {
                 s4.push(s5);
                 if (peg$c128.test(input.charAt(peg$currPos))) {
                   s5 = input.charAt(peg$currPos);
                   peg$currPos++;
                 } else {
                   s5 = peg$FAILED;
                   if (peg$silentFails === 0) { peg$fail(peg$c129); }
                 }
               }
             } else {
               s4 = peg$FAILED;
             }
           }
         }
         if (s4 !== peg$FAILED) {
           s2 = [s2, s3, s4];
           s1 = s2;
         } else {
           peg$currPos = s1;
           s1 = peg$FAILED;
         }
       } else {
         peg$currPos = s1;
         s1 = peg$FAILED;
       }
     } else {
       peg$currPos = s1;
       s1 = peg$FAILED;
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c130(s1);
     }
     s0 = s1;

     return s0;
   }

   function peg$parseNEWLINE() {
     var s0, s1, s2;

     s0 = peg$currPos;
     s1 = peg$parsecomment();
     if (s1 === peg$FAILED) {
       s1 = null;
     }
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c131) {
         s2 = peg$c131;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c132); }
       }
       if (s2 === peg$FAILED) {
         if (input.charCodeAt(peg$currPos) === 10) {
           s2 = peg$c133;
           peg$currPos++;
         } else {
           s2 = peg$FAILED;
           if (peg$silentFails === 0) { peg$fail(peg$c134); }
         }
         if (s2 === peg$FAILED) {
           if (input.charCodeAt(peg$currPos) === 13) {
             s2 = peg$c135;
             peg$currPos++;
           } else {
             s2 = peg$FAILED;
             if (peg$silentFails === 0) { peg$fail(peg$c136); }
           }
         }
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c137(s1);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCOMMENT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     if (input.charCodeAt(peg$currPos) === 35) {
       s1 = peg$c138;
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c139); }
     }
     if (s1 !== peg$FAILED) {
       s2 = [];
       if (peg$c140.test(input.charAt(peg$currPos))) {
         s3 = input.charAt(peg$currPos);
         peg$currPos++;
       } else {
         s3 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c141); }
       }
       if (s3 !== peg$FAILED) {
         while (s3 !== peg$FAILED) {
           s2.push(s3);
           if (peg$c140.test(input.charAt(peg$currPos))) {
             s3 = input.charAt(peg$currPos);
             peg$currPos++;
           } else {
             s3 = peg$FAILED;
             if (peg$silentFails === 0) { peg$fail(peg$c141); }
           }
         }
       } else {
         s2 = peg$FAILED;
       }
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c119(s2);
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parse__() {
     var s0, s1;

     s0 = [];
     if (peg$c142.test(input.charAt(peg$currPos))) {
       s1 = input.charAt(peg$currPos);
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c143); }
     }
     if (s1 !== peg$FAILED) {
       while (s1 !== peg$FAILED) {
         s0.push(s1);
         if (peg$c142.test(input.charAt(peg$currPos))) {
           s1 = input.charAt(peg$currPos);
           peg$currPos++;
         } else {
           s1 = peg$FAILED;
           if (peg$silentFails === 0) { peg$fail(peg$c143); }
         }
       }
     } else {
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parse_() {
     var s0, s1;

     s0 = [];
     if (peg$c142.test(input.charAt(peg$currPos))) {
       s1 = input.charAt(peg$currPos);
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c143); }
     }
     while (s1 !== peg$FAILED) {
       s0.push(s1);
       if (peg$c142.test(input.charAt(peg$currPos))) {
         s1 = input.charAt(peg$currPos);
         peg$currPos++;
       } else {
         s1 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c143); }
       }
     }

     return s0;
   }

   function peg$parseEOF() {
     var s0, s1;

     s0 = peg$currPos;
     peg$silentFails++;
     if (input.length > peg$currPos) {
       s1 = input.charAt(peg$currPos);
       peg$currPos++;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c120); }
     }
     peg$silentFails--;
     if (s1 === peg$FAILED) {
       s0 = void 0;
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseAWAIT() {
     var s0;

     if (input.substr(peg$currPos, 5) === peg$c144) {
       s0 = peg$c144;
       peg$currPos += 5;
     } else {
       s0 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c145); }
     }

     return s0;
   }

   function peg$parseEOL() {
     var s0;

     if (input.substr(peg$currPos, 2) === peg$c131) {
       s0 = peg$c131;
       peg$currPos += 2;
     } else {
       s0 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c132); }
     }
     if (s0 === peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 10) {
         s0 = peg$c133;
         peg$currPos++;
       } else {
         s0 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c134); }
       }
       if (s0 === peg$FAILED) {
         if (input.charCodeAt(peg$currPos) === 13) {
           s0 = peg$c135;
           peg$currPos++;
         } else {
           s0 = peg$FAILED;
           if (peg$silentFails === 0) { peg$fail(peg$c136); }
         }
       }
     }

     return s0;
   }

   function peg$parseAT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 64) {
         s2 = peg$c146;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c147); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c148();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseASYNC() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 5) === peg$c149) {
         s2 = peg$c149;
         peg$currPos += 5;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c150); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c151();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDEF() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 3) === peg$c152) {
       s1 = peg$c152;
       peg$currPos += 3;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c153); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse__();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c154();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseLAMBDA_ARROW() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c155) {
         s2 = peg$c155;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c156); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c157();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCOLON() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 58) {
         s2 = peg$c158;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c159); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c160();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseOPEN_PAREN() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 40) {
         s2 = peg$c161;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c162); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c163();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCLOSE_PAREN() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 41) {
         s2 = peg$c164;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c165); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c166();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseEQUALS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 61) {
         s2 = peg$c167;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c168); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c169();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCOMMA() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 44) {
         s2 = peg$c170;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c171); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c172();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseSLASH() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 47) {
         s2 = peg$c173;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c174); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c175();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseSTAR() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 42) {
         s2 = peg$c176;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c177); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c178();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLESTAR() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c179) {
         s2 = peg$c179;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c180); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c181();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseSEMICOLON() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 59) {
         s2 = peg$c182;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c183); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c184();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsePLUSEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c185) {
         s2 = peg$c185;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c186); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c187();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseMINEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c188) {
         s2 = peg$c188;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c189); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c190();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseSTAREQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c191) {
         s2 = peg$c191;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c192); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c193();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseATEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c194) {
         s2 = peg$c194;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c195); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c196();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseSLASHEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c197) {
         s2 = peg$c197;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c198); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c199();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsePERCENTEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c200) {
         s2 = peg$c200;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c201); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c202();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseAMPEREQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c203) {
         s2 = peg$c203;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c204); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c205();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseVBAREQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c206) {
         s2 = peg$c206;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c207); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c208();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCIRCUMFLEXEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c209) {
         s2 = peg$c209;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c210); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c211();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseLEFTSHIFTEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c212) {
         s2 = peg$c212;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c213); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c214();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseRIGHTSHIFTEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c215) {
         s2 = peg$c215;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c216); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c217();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLESTAREQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c218) {
         s2 = peg$c218;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c219); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c220();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLESLASHEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c221) {
         s2 = peg$c221;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c222); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c223();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseEXEC() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c224) {
         s2 = peg$c224;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c225); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c226();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDEL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c227) {
         s2 = peg$c227;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c228); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c229();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsePASS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c230) {
         s2 = peg$c230;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c231); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c232();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseBREAK() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 5) === peg$c233) {
         s2 = peg$c233;
         peg$currPos += 5;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c234); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c235();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCONTINUE() {
     var s0, s1;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 8) === peg$c236) {
       s1 = peg$c236;
       peg$currPos += 8;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c237); }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c238();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseRETURN() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 6) === peg$c239) {
         s2 = peg$c239;
         peg$currPos += 6;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c240); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c241();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseRAISE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 5) === peg$c242) {
         s2 = peg$c242;
         peg$currPos += 5;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c243); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c244();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseFROM() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c245) {
         s2 = peg$c245;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c246); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c247();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseIMPORT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 6) === peg$c248) {
         s2 = peg$c248;
         peg$currPos += 6;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c249); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c250();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 46) {
         s2 = peg$c251;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c252); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c253();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLEDOT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c254) {
         s2 = peg$c254;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c255); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c256();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseELLIPSIS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c257) {
         s2 = peg$c257;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c258); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c259();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseAS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c260) {
         s2 = peg$c260;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c261); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c262();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseGLOBAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 6) === peg$c263) {
         s2 = peg$c263;
         peg$currPos += 6;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c264); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c265();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNONLOCAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 8) === peg$c266) {
         s2 = peg$c266;
         peg$currPos += 8;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c267); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c268();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseASSERT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 6) === peg$c269) {
         s2 = peg$c269;
         peg$currPos += 6;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c270); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c271();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseIF() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c272) {
         s2 = peg$c272;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c273); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c274();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseELIF() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c275) {
         s2 = peg$c275;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c276); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c277();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseELSE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c278) {
         s2 = peg$c278;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c279); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c280();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseWHILE() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 5) === peg$c281) {
       s1 = peg$c281;
       peg$currPos += 5;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c282); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse_();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c283();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsePRINT() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 5) === peg$c284) {
       s1 = peg$c284;
       peg$currPos += 5;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c285); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse_();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c286();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseFOR() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 3) === peg$c287) {
       s1 = peg$c287;
       peg$currPos += 3;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c288); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse_();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c289();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseIN() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c290) {
         s2 = peg$c290;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c291); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c292();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseTRY() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 3) === peg$c293) {
       s1 = peg$c293;
       peg$currPos += 3;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c294); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse_();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c295();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseFINALLY() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 7) === peg$c296) {
       s1 = peg$c296;
       peg$currPos += 7;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c297); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse_();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c298();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseWITH() {
     var s0, s1, s2;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 4) === peg$c299) {
       s1 = peg$c299;
       peg$currPos += 4;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c300); }
     }
     if (s1 !== peg$FAILED) {
       s2 = peg$parse_();
       if (s2 !== peg$FAILED) {
         peg$savedPos = s0;
         s1 = peg$c301();
         s0 = s1;
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseEXCEPT() {
     var s0, s1;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 6) === peg$c302) {
       s1 = peg$c302;
       peg$currPos += 6;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c303); }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c304();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseCOLONEQUAL() {
     var s0, s1;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 2) === peg$c305) {
       s1 = peg$c305;
       peg$currPos += 2;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c306); }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c307();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseLAMBDA() {
     var s0, s1;

     s0 = peg$currPos;
     if (input.substr(peg$currPos, 6) === peg$c308) {
       s1 = peg$c308;
       peg$currPos += 6;
     } else {
       s1 = peg$FAILED;
       if (peg$silentFails === 0) { peg$fail(peg$c309); }
     }
     if (s1 !== peg$FAILED) {
       peg$savedPos = s0;
       s1 = peg$c310();
     }
     s0 = s1;

     return s0;
   }

   function peg$parseOR() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c311) {
         s2 = peg$c311;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c312); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c313();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseAND() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c314) {
         s2 = peg$c314;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c315); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c316();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNOT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 3) === peg$c317) {
         s2 = peg$c317;
         peg$currPos += 3;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c318); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c319();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseLESS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 60) {
         s2 = peg$c320;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c321); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c322();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseGREATER() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 62) {
         s2 = peg$c323;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c324); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c325();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLE_EQUALS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c326) {
         s2 = peg$c326;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c327); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c328();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseGREATEREQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c329) {
         s2 = peg$c329;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c330); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c331();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseLESSEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c332) {
         s2 = peg$c332;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c333); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c334();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNOTEQUAL_EXCEL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c335) {
         s2 = peg$c335;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c336); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c337();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNOTEQUAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c338) {
         s2 = peg$c338;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c339); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c340();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseIS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c341) {
         s2 = peg$c341;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c342); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c343();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseVBAR() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 124) {
         s2 = peg$c344;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c345); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c346();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCIRCUMFLEX() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 94) {
         s2 = peg$c347;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c348); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c349();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseAMPER() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 38) {
         s2 = peg$c350;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c351); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c352();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseLEFTSHIFT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c353) {
         s2 = peg$c353;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c354); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c355();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseRIGHTSHIFT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c356) {
         s2 = peg$c356;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c357); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c358();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsePLUS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 43) {
         s2 = peg$c359;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c360); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c361();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseMINUS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 45) {
         s2 = peg$c122;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c123); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c362();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parsePERCENT() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 37) {
         s2 = peg$c363;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c364); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c365();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLESLASH() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c366) {
         s2 = peg$c366;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c367); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c368();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseTILDE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 126) {
         s2 = peg$c369;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c370); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c371();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseOPEN_SQUARE_BRACKET() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 91) {
         s2 = peg$c372;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c373); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c374();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCLOSE_SQUARE_BRACKET() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 93) {
         s2 = peg$c375;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c376); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c377();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseOPEN_CURLY_BRACKET() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 123) {
         s2 = peg$c378;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c379); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c380();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCLOSE_CURLY_BRACKET() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 125) {
         s2 = peg$c381;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c382); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c383();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNONE_LITERAL() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c384) {
         s2 = peg$c384;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c385); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c386();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseTRUE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 4) === peg$c387) {
         s2 = peg$c387;
         peg$currPos += 4;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c388); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c389();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseFALSE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 5) === peg$c390) {
         s2 = peg$c390;
         peg$currPos += 5;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c391); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c392();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseCLASS() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 5) === peg$c393) {
         s2 = peg$c393;
         peg$currPos += 5;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c394); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c395();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseYIELD() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 5) === peg$c396) {
         s2 = peg$c396;
         peg$currPos += 5;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c397); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c398();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseRARROW() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.substr(peg$currPos, 2) === peg$c155) {
         s2 = peg$c155;
         peg$currPos += 2;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c156); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c157();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseDOUBLE_QUOTE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 34) {
         s2 = peg$c399;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c400); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c401();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseSINGLE_QUOTE() {
     var s0, s1, s2, s3;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (input.charCodeAt(peg$currPos) === 39) {
         s2 = peg$c402;
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c403); }
       }
       if (s2 !== peg$FAILED) {
         s3 = peg$parse_();
         if (s3 !== peg$FAILED) {
           peg$savedPos = s0;
           s1 = peg$c404();
           s0 = s1;
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }

   function peg$parseNAME() {
     var s0, s1, s2, s3, s4, s5;

     s0 = peg$currPos;
     s1 = peg$parse_();
     if (s1 !== peg$FAILED) {
       if (peg$c405.test(input.charAt(peg$currPos))) {
         s2 = input.charAt(peg$currPos);
         peg$currPos++;
       } else {
         s2 = peg$FAILED;
         if (peg$silentFails === 0) { peg$fail(peg$c406); }
       }
       if (s2 !== peg$FAILED) {
         s3 = [];
         if (peg$c407.test(input.charAt(peg$currPos))) {
           s4 = input.charAt(peg$currPos);
           peg$currPos++;
         } else {
           s4 = peg$FAILED;
           if (peg$silentFails === 0) { peg$fail(peg$c408); }
         }
         while (s4 !== peg$FAILED) {
           s3.push(s4);
           if (peg$c407.test(input.charAt(peg$currPos))) {
             s4 = input.charAt(peg$currPos);
             peg$currPos++;
           } else {
             s4 = peg$FAILED;
             if (peg$silentFails === 0) { peg$fail(peg$c408); }
           }
         }
         if (s3 !== peg$FAILED) {
           s4 = peg$parse_();
           if (s4 !== peg$FAILED) {
             peg$savedPos = peg$currPos;
             s5 = peg$c409(s2, s3);
             if (s5) {
               s5 = void 0;
             } else {
               s5 = peg$FAILED;
             }
             if (s5 !== peg$FAILED) {
               peg$savedPos = s0;
               s1 = peg$c410(s2, s3);
               s0 = s1;
             } else {
               peg$currPos = s0;
               s0 = peg$FAILED;
             }
           } else {
             peg$currPos = s0;
             s0 = peg$FAILED;
           }
         } else {
           peg$currPos = s0;
           s0 = peg$FAILED;
         }
       } else {
         peg$currPos = s0;
         s0 = peg$FAILED;
       }
     } else {
       peg$currPos = s0;
       s0 = peg$FAILED;
     }

     return s0;
   }


     var indentLevel = 0;
     var singleIndentLen = 1;


   peg$result = peg$startRuleFunction();

   if (peg$result !== peg$FAILED && peg$currPos === input.length) {
     return peg$result;
   } else {
     if (peg$result !== peg$FAILED && peg$currPos < input.length) {
       peg$fail(peg$endExpectation());
     }

     throw peg$buildStructuredError(
       peg$maxFailExpected,
       peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
       peg$maxFailPos < input.length
         ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
         : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
     );
   }
 }

 return {
   SyntaxError: peg$SyntaxError,
   parse:       peg$parse
 };
})();

if(typeof window === "undefined") module.exports = pythonParser;

export default pythonParser