import { useState } from "react"
import LevelsAdminService from "../api/services/LevelsAdminService"
import ValidatorsAdminService from "../api/services/ValidatorsAdminService"
import { useEffect } from "react"
import { usePy } from "../context/PyContext"



export const useValidatorService = () => {
    const {output, clearOutput,  sendMsg, run }   = usePy()

    const [ isLoading, setIsLoading]         = useState(false)
    const [ selectedState, setSelectedState] = useState("-1") // index
    const [ levels, setLevels ]         = useState([])
    const [ validators, setValidators ] = useState([])

    const [code, setCode] = useState("")
    const [luxOutput, setLuxOutput] = useState({})

    const initlevels     = async () => setLevels(await LevelsAdminService.all())
    const initValidators = async () => setValidators(await ValidatorsAdminService.all())
    
    const [ title, setTitle ]                   = useState("")
    const [ description, setDescription ]       = useState("")
    const [ body, setBody ]                     = useState("")
    const [ validatorType, setValidatorType ]   = useState("JS_BASIC_LX_AUX")


    const init = async () => {
        Promise.all([initlevels(), initValidators()])
        .catch((err) => console.error(err))
    }

    useEffect(() => {
        let mounted = false;

        if(!mounted){
            init()
        }

        return () => {mounted = true}
    }, [])


    const serialize = () => {
        if(selectedState === -1 || selectedState === "-1"){
            return {
                title,
                description,
                validatorType,
                body
            }
        }
        else{
            return {
                id:validators[selectedState].id,
                title,
                description,
                validatorType,
                body
            }
        }
    }


    const resetForm = () => {
        setSelectedState("-1")
        setTitle("")
        setDescription("")
        setBody("")
        setValidatorType("JS_BASIC_LX_AUX")
    }


    const onRemoveValidator = () => {
        if(selectedState !== "-1" && serialize().id){
            ValidatorsAdminService.destroy(serialize().id)
            .then(() => init())
            .catch((err) => console.error(err))
        }
    }

    const onRun = (answer) => {
        run(answer)
    }

    const onValidatorRun = () => {
        ValidatorsAdminService.validate(code, validatorType, body, 25)
        .then((res) => {
            setLuxOutput(res)
            if(res.isCorrect === true){
                sendMsg("successful", 'الجواب صحيح')
            }
            else if(res.isCorrect === false){
                sendMsg("error", 'الجواب غير صحيح')
            }
        })
        .catch((err) => console.error(err))
    }

    const onSubmit = () => {
        if(selectedState === -1 || selectedState === "-1"){
            //create
            ValidatorsAdminService.create(serialize())
            .then((res) => {
                setValidators((prev) => [res, ...prev])
                resetForm()
            })
            .catch((err) => console.error(err))
        }   
        else{
            // edit
            ValidatorsAdminService.patch(serialize().id, serialize())
            .then((res) => {
                const temp = validators.map((validator) => {
                    if(validator.id === res.id){
                        return res
                    }
                    return validator
                })
                setValidators((prev) => temp)
            })
            .catch((err) => console.error(err))
        }
    }

    const onSelectedStateChange = (value) => {
        if(value === -1 || value === "-1"){ 
            setSelectedState(value)
            resetForm() 
        }
        else{
            setTitle(validators[value]?.title)
            setBody(validators[value].body)
            setDescription(validators[value]?.description ? validators[value]?.description : "")
            setValidatorType(validators[value].levelType)
        }


        setSelectedState(value)
    }

    const onBodyChange = (value) => {
        setBody(value)
    }

    return {
        isLoading,
        levels,
        validators,
        onSubmit,
        selectedState,
        onSelectedStateChange,
        title,
        description,
        body,
        validatorType,
        setValidatorType,
        onBodyChange,
        code,
        setCode,
        output,
        clearOutput,
        onRun,
        onValidatorRun,
        luxOutput,
        setLuxOutput,
        setTitle,
        setDescription,
        onRemoveValidator
    }
}